<template>
  <v-card class="mx-auto text-center" width="75%" justify="center">
    <v-card-text v-show="show">
      <p class="display-1 text--primary">{{title}}</p>
      <p>{{sub_title}}</p>
      <v-btn class="ma-2" color="primary" :to="action_link">
        {{action_text}}
        <v-icon dark right>{{action_icon}}</v-icon>
      </v-btn>
      <div class="caption mt-4">
              {{footer_text}}
    </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props:['show','title','sub_title','action_text','action_link','action_icon','footer_text'],
  data: () => {
    return {
      
    };
  }
};
</script>