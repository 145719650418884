<template>
  <v-container fill-height >
    <MjAlreadyLoggedDialog></MjAlreadyLoggedDialog>
    <v-card v-show="!show_success_box" class="mx-auto grey lighten-5 p-5" max-width="480px" width="75vw" elevation="2" rounded>

      <v-card-title class="body-1 justify-center">Resetta Password</v-card-title>
      <v-card-text class="px-12 mb-12">
        <p class="grey--text">
          Indica la tua email e una nuova password di almeno 8 caratteri.
        </p>
        <v-form ref="form" v-model="valid" :lazy-validation="lazy" v-show="show_form">
          <v-text-field
              outlined
              v-model="form.email"
              :append-icon="'mdi-email-outline'"
              :rules="emailRules"
              label="E-mail"
              required
          ></v-text-field>
          <v-text-field
              outlined
              v-model="form.password"
              :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show_password ? 'text' : 'password'"
              counter
              :rules="passwordRules"
              label="Password"
              @click:append="show_password = !show_password"
              required
          ></v-text-field>
          <v-btn
              type='submit'
              :disabled="!valid || recupera_pwd_btn_loading"
              large
              color="primary"
              block
              class="mr-4"
              :loading="recupera_pwd_btn_loading"
              @click.prevent="submit"
          >Conferma Nuova Password</v-btn>
          <v-alert v-if="error_show" type="warning" class="mt-2 py-2 warning">
            <span v-text="error_text"></span>
          </v-alert>

        </v-form>
      </v-card-text>
    </v-card>


    <v-card class="mx-auto text-center" min-width="65vw" v-show="show_success_box">
      <v-card-text>
        <p class="display-1 text--primary">Ben Fatto!</p>
        <p>Abbiamo impostato la nuova password</p>
        <v-btn class="ma-2" color="primary" to="/utente/login">
          Vai al Login
          <v-icon dark right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import mj_axios from "@/mj_axios.js";
import MjAlreadyLoggedDialog from "@/components/MjAlreadyLoggedDialog";
export default {
  name: "ResetPasswordView",
  components: {MjAlreadyLoggedDialog},
  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build
  data() {
    return {
    valid: true,
    recupera_pwd_btn_loading: false,
    show_password: false,
    show_success_box: false,
    show_form: true,
    error_show: false,
    error_text: "",
     form: {
      password: "",
      email: ""
    },
    passwordRules: [
      v => !!v || "Password Obbligatora is required",
      v => (v && v.length >= 8) || "Digita almeno 8 caratteri"
    ],

    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    lazy: false,
    token: this.$route.query.token || "",
  };
  },

  methods: {
    submit() {
      
      if (this.$refs.form.validate()) {
       
          this.recupera_pwd_btn_loading = true;

          const params = {
            email: this.form.email,
            password: this.form.password,
            password_confirmation: this.form.password,
            token: this.token
          };
          
          mj_axios
            .post("/api/user/password/do_reset", params)
            .then(() => {
              this.show_success_box = true;
              this.show_form = false;
            })
            .catch(e => {
              this.error_show = true;
              
              this.error_text = e.response.data.message;
            })
            .then(() => {
              // always executed
              this.recupera_pwd_btn_loading = false;
            });
        
      }
    },
    // resetCaptcha(){this.$refs.recaptcha.resetRecaptcha()},
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  },
 
};
</script>
