

const mj_array_utils = {

    sumReduce(array) {
        return array.reduce((sum, val) => {
            return Number.isInteger(val) ? sum + val : sum + 0
        }, 0);
    },
}

export default mj_array_utils;