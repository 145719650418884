<template>
  <v-container fluid>
    <WeekNavigator @change="changeWeek" class="mb-4"/>
    <!--    TITOLO VIEW e DESCRIZIONE IMPIANTO DI PRODUZIONE-->
    <v-container>
      <mj-soft-title class="mb-3" icon="mdi-factory"
                     :title="`Richiesta Viaggi / ${selectedProductionPlant && selectedProductionPlant.name ? selectedProductionPlant.name : ''}`"></mj-soft-title>
      <v-progress-linear class="ml-3" style="width: 260px" v-show="loadingSelectedProductionPlant > 0" small
                         indeterminate></v-progress-linear>
    </v-container>
    <!--TOOLBAR PER LE AZIONI SUI DATI/TABELLA-->
    <v-toolbar class="gradient_background mb-2" elevation="1" flat rounded dense>
      <v-layout row justify-left align-center>
        <!--save-->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
                :loading="savingShipmentRequests > 0 || savingProductStock > 0"
                :disabled="!saveEnabled"
                icon
                v-on="on"
                color="primary"
                @click="saveShipmentRequests(productionPlantIdPathParam, selectedWeekId);saveProductStocks()"
                v-shortkey="['meta', 's']"
                @shortkey="saveShipmentRequests(productionPlantIdPathParam, selectedWeekId);saveProductStocks()"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>Salva</span>
        </v-tooltip>
        <!--refresh-->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
                icon
                v-on="on"
                color="secondary"
                @click="loadShipmentRequests(productionPlantIdPathParam, selectedWeekId);loadProductTypesForProductionPlant($route.params.productionPlantId);"
                v-shortkey="['meta', 'r']"
                @shortkey="loadShipmentRequests(productionPlantIdPathParam, selectedWeekId);loadProductTypesForProductionPlant($route.params.productionPlantId);"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Ricarica</span>
        </v-tooltip>

      </v-layout>

    </v-toolbar>

    <!-- COSTRUZIONE TABELLA CON I DATI-->
    <v-simple-table class="planning_table" style="width: 100%">
      <thead>
      <tr class="second_font">
        <th class="week_th">
          {{ mj_date_utils.getNextWeekFromToDescription() }}
          <v-progress-linear indeterminate v-show="loadingProductTypes > 0 "></v-progress-linear>
        </th>
        <th v-for="day in daysHeader" class="day_cell text-center" :key="day">{{ day }}</th>
        <th class="text-center">Tot</th>
        <th class="text-center">Giacenza
          <v-progress-linear indeterminate v-show="savingProductStock > 0"></v-progress-linear>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="loadingShipmentRequests > 0">
        <td class="loader_td" colspan="100">
          <v-progress-linear indeterminate></v-progress-linear>
        </td>
      </tr>
      <tr v-for="(productType,index) in productTypes" :key="index">
        <td :style="{color:`#${productType.color_hex}`}">{{ productType.cod }}</td>
        <template v-if="shipmentRequests != null">
          <td v-for="(value,dayIndex) in getDaysIndexedShipmentRequestCount(productType.id)"
              class="pa-0 ma-0 day_cell editable_cell"
              :key="productType.id+''+dayIndex">
            <v-text-field solo v-model.number="getDaysIndexedShipmentRequestCount(productType.id)[dayIndex]"
                          dense flat hide-details full-width type="number" hide-spin-buttons reverse
                          class="text-field-transparent align-baseline"
                          @change="shipmentRequestChangedHandler"
                          :disabled="savingShipmentRequests > 0"
            >
              <template v-slot:append-outer>

                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-on="on"
                           v-bind="attrs"
                           :color="getDaysIndexedPackagingTypes(productType.id)[dayIndex] != null ? 'primary' : 'grey lighten-1'">
                      <v-icon small class="mr-1">mdi-package-variant</v-icon>
                    </v-btn>
                  </template>


                  <v-sheet>
                    <v-autocomplete class="px-2" :loading="loadingPackagingTypes > 0"
                                    v-model="getDaysIndexedPackagingTypes(productType.id)[dayIndex]"
                                    placeholder="Imballaggio" clearable
                                    item-text="cod" item-value="id" :items="packagingTypes"
                                    @change="shipmentRequestChangedHandler"
                                    :disabled="savingShipmentRequests > 0"
                    >
                    </v-autocomplete>
                  </v-sheet>

                </v-menu>


              </template>
            </v-text-field>
          </td>
          <td class="text-right font-weight-bold tot">
            {{
              getDaysIndexedShipmentRequestCount(productType.id).reduce((sum, val) => {
                return Number.isInteger(val) ? sum + val : sum + 0
              }, 0)
            }}
          </td>
        </template>
        <!-- SE ANCORA NON HO CARICATO GLI SHIPMENT REQUEST MOSTRO CELLE VUOTE -->
        <template v-else>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </template>
        <td class="text-right primary--text stock  editable_cell pa-0 ma-0">
          <v-text-field solo v-model="productType.stock_quantity" dense flat hide-details full-width hide-spin-buttons
                        reverse
                        @change="stockChangedHandler"
                        class="text-field-transparent">
          </v-text-field>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
  </v-container>
</template>
<script>

import MjSoftTitle from "@/components/MjSoftTitle";
import mj_axios from "@/mj_axios";
import mj_date_utils from "@/misc/mj_date_utils.js";
import ReactivityMixin from "@/mixins/ReactivityMixin";
import ProductTypeMixin from "@/mixins/entities/ProductTypeMixin";
import PackagingTypeMixin from "@/mixins/entities/PackagingTypeMixin";
import ProductionPlantMixin from "@/mixins/entities/ProductionPlantMixin";
import WeekNavigator from "@/components/custom/WeekNavigator";
import ShipmentRequestMixin from "@/mixins/entities/ShipmentRequestMixin";


export default {
  name: "PlanningRequest",
  components: {MjSoftTitle, WeekNavigator},
  mixins: [ReactivityMixin, ProductTypeMixin, PackagingTypeMixin, ProductionPlantMixin, ShipmentRequestMixin],
  computed: {
    saveEnabled: function () {
      return this.shipmentRequestChanged || this.stockChanged;
    },
    productionPlantIdPathParam: function() {
      return this.$route.params.productionPlantId
    }
  },
  created() {
    this.loadSelectedProductionPlant(this.$route.params.productionPlantId);
    this.loadPackagingTypes(); //necessario per gli autocomplete degli imballi
    this.loadProductTypesForProductionPlant(this.$route.params.productionPlantId);
    // this.loadShipmentRequests(this.productionPlantIdPathParam, this.selectedWeekId);
  },

  data: function () {
    return {
      mj_date_utils: mj_date_utils,
      daysHeader: ['LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB'],


      savingShipmentRequests: 0,
      shipmentRequestChanged: false,
      stockChanged: false,
      savingProductStock: 0,


      loadingSelectedProductionPlant: 0,
      selectedProductionPlant: {},

      selectedWeekId: null,
    }
  },
  methods: {

    getDaysIndexedShipmentRequestCount(productTypeId) {
      const productTypeWeeklyRequests = this.shipmentRequests.productTypeWeeklyRequests.find((productTypeWeeklyRequest) => {
        return productTypeWeeklyRequest.productTypeId == productTypeId;
      });
      return productTypeWeeklyRequests.daysIndexedShipmentRequestCount;
    },
    getDaysIndexedPackagingTypes(productTypeId) {
      const productTypeWeeklyRequests = this.shipmentRequests.productTypeWeeklyRequests.find((productTypeWeeklyRequest) => {
        return productTypeWeeklyRequest.productTypeId == productTypeId;
      });
      return productTypeWeeklyRequests.daysIndexedPackagingTypeId;
    },

    changeWeek(weekId) {
      this.selectedWeekId = weekId;
      this.loadShipmentRequests(this.productionPlantIdPathParam, this.selectedWeekId);
    },


    stockChangedHandler() {
      this.stockChanged = true;
    },
    shipmentRequestChangedHandler() {
      this.shipmentRequestChanged = true;
    },

    saveProductStocks() {

      this.savingProductStock++;
      const params = JSON.stringify(this.productTypes);
      const axios_promise = mj_axios.post(`/api/production_plants/${this.$route.params.productionPlantId}/product_types/actions/update_stocks`, params);
      axios_promise.then(() => {
        this.stockChanged = false;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.savingProductStock--;
      });
      return axios_promise;
    }
  }
}
</script>

<style scoped>
.week_th {
  min-width: 170px;
}

.planning_table {
  border: solid 1px #222;
  border-radius: 5px 5px;
  overflow: hidden;
}


.planning_table td,
.planning_table th {
  border: solid 1px #ddd;
  border-collapse: collapse;
}


.planning_table tbody {
  background-color: white;
}

.planning_table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .01);
}

.planning_table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, .03);
}

.planning_table .day_cell {
  min-width: 100px;
  max-width: 100px;
}

.planning_table .stock {
  min-width: 80px;
  max-width: 80px;
}

.planning_table .tot {
  min-width: 80px;
  max-width: 80px;
}

.planning_table .tot:nth-of-type(even) {
  background-color: rgba(0, 128, 128, .06);
}

.planning_table .tot:nth-of-type(odd) {
  background-color: rgba(0, 128, 128, .03);
}

.planning_table .stock:nth-of-type(even) {
  background-color: rgba(255, 187, 0, 0.5);
}

.planning_table .stock:nth-of-type(odd) {
  background-color: rgba(255, 187, 0, 0.25);
}
</style>