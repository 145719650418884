import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router.js';
import store from './store.js';

// Importing the global css file
import "@/assets/css/common_style.css"
import "@/assets/css/custom_style.css"

//GLOBAL VUE CONFIG
Vue.config.productionTip = false
Vue.use(require('vue-shortkey'));
new Vue({
    store,
    data: {},
    beforeCreate: function () {
        store.dispatch('fetchClientVar');
    },
    vuetify,
    router,
    render: function (createElement) {
        return createElement(App);
    } //traducibile anche render: h => h(App); h è alias di createElement
    ,
}).$mount('#app'); //monto manualmente la vm sul dom nell'elemento id