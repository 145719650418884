<template>
  <v-container fluid>
    <v-container>
      <mj-soft-title class="mb-3" icon="mdi-factory" title="Lista Impianti di Produzione"></mj-soft-title>
    </v-container>
    <v-simple-table class="mj_simple_table">
      <thead>
      <tr>
        <th>Alias</th>
        <th>Impianto</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-show="loading > 0 ">
        <td colspan="20" class="loader_td">
          <v-progress-linear indeterminate></v-progress-linear>
        </td>
      </tr>
      <tr v-for="productionPlant in myProductionPlants" :key="productionPlant.id">
        <td>{{ productionPlant.cod }}</td>
        <td>{{ productionPlant.name }}</td>
        <td>
          <v-btn small color="primary" @click="goToPlanningRequest(productionPlant.id)">Pianifica <v-icon small right>mdi-arrow-right</v-icon></v-btn>
        </td>
      </tr>
      </tbody>
    </v-simple-table>

  </v-container>
</template>

<script>

import MjSoftTitle from "@/components/MjSoftTitle";
import mj_axios from "@/mj_axios";
// import PlanningRequest from "@/views/planning/PlanningRequest";


export default {
  name: "MyProductionPlants",
  components: {MjSoftTitle},
  created() {
    this.loadMyProductionPlants();
  },
  data: function () {
    return {
      loading: 0,
      myProductionPlants: []
    }
  },
  methods: {
    goToPlanningRequest(productionPlantId){
       this.$router.push({ path: `/planning/request/${productionPlantId}`});
    },
    loadMyProductionPlants() {
      this.loading++;
      const axios_promise = mj_axios.get('/api/users/production_plants');
      axios_promise.then(response => {
        this.myProductionPlants = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    }
  }
}
</script>

<style scoped>

</style>