/*
Il componente viene utilizzato nelle view che richiedono un caricamento periodico dei dati.
Riceve in input la funzione da richiamare e la quantità di millisecondi per impostare la frequenza.

Graficamente viene mostrata una progress bar (opzionale) e la data ultimo aggiornamento.
*/

<template>
  <v-container class="px-1 pt-0">
    <div v-if="showLastUpdateTimeStamp" class="text--secondary small">
      <span class="text--disabled">Last Update: </span>{{ lastUpdateTimestamp ? lastUpdateTimestamp : ' - ' }}
    </div>
    <v-progress-linear v-if="showProgressBar" indeterminate v-show="loading > 0"></v-progress-linear>
  </v-container>
</template>

<script>
import moment from "moment"

export default {
  name: "MjIntervalUpdater",
  props: {
    updateFunction: {},
    updateIntervalMillis: {default: () => 5000},
    showProgressBar: {default: () => true},
    showLastUpdateTimeStamp: {default: () => true}
  },

  mounted() {
    //con il settimeout il primo caricamento avviene dopo updateIntervalMillis
    setTimeout(this.callUpdateFunction, this.updateIntervalMillis);

  },
  data: () => ({
    loading: 0,

    lastUpdateTimestamp: null,
  }),
  methods: {
    callUpdateFunction: function () {
      this.loading++;
      this.updateFunction().then(() => {
        this.lastUpdateTimestamp = moment().format('DD/MM/YYYY HH:mm:ss');
        setTimeout(this.callUpdateFunction, this.updateIntervalMillis)
      }).catch(() => {
      }).then(() => this.loading--);
    }
  }

}
</script>

<style scoped>

</style>