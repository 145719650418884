const mj_anagrafica_utils = {

  validatePIva(piva) {
    let resp = { code: 1, message: '' };

    if (piva.length === 0) {
      resp.code = -1;
      resp.message = "Partita iva non immessa";
    }
    else if (piva.length !== 11) {
      resp.code = -2;
      resp.message = "Lunghezza Partita Iva non valida";
    }
    else if (! /^[0-9]{11}$/.test(piva)) {
      resp.code = -3;
      resp.message = "Uno o più caratteri non validi";
    }
    if (resp.code > 0) {
      var s = 0;
      for (var i = 0; i < 11; i++) {
        var n = piva.charCodeAt(i) - "0".charCodeAt(0);
        if ((i & 1) === 1) {
          n *= 2;
          if (n > 9)
            n -= 9;
        }
        s += n;
      }
      if (s % 10 !== 0) {
        resp.code = -4;
        resp.message = "Partita Iva Non Valida";
      }
    }
    return resp;
  },


  /**
   * Validates a regular CF.
   * @param string cf Normalized, 16 characters CF.
   * @return string Null if valid, or string describing why this CF must be
   * rejected.
   */
  validate_regular_cf(cf) {

    let resp = { code: 1, message: '' };
    cf = cf.toUpperCase();
    if (! /^[0-9A-Z]{16}$/.test(cf)) { resp.code = -3; resp.message = 'Caratteri non validi'; }
    var s = 0;
    var even_map = "BAFHJNPRTVCESULDGIMOQKWZYX";
    for (var i = 0; i < 15; i++) {
      var c = cf[i];
      var n = 0;
      if ("0" <= c && c <= "9")
        n = c.charCodeAt(0) - "0".charCodeAt(0);
      else
        n = c.charCodeAt(0) - "A".charCodeAt(0);
      if ((i & 1) === 0)
        n = even_map.charCodeAt(n) - "A".charCodeAt(0);
      s += n;
    }
    if (s % 26 + "A".charCodeAt(0) !== cf.charCodeAt(15)) {
      resp.code = -4;
      resp.message = 'Codice Fiscale Non Valido';
    }
    return resp;
  },


  /**
   * Validates a temporary CF.
   * @param string cf Normalized, 11 characters CF.
   * @return string Null if valid, or string describing why this CF must be
   * rejected.
   */
  validate_temporary_cf(cf) {

    let resp = { code: 1, message: '' };

    if (! /^[0-9]{11}$/.test(cf)) {
      resp.code = -5;
      resp.message = 'Caratteri non validi';
    }
    var s = 0;
    for (var i = 0; i < 11; i++) {
      var n = cf.charCodeAt(i) - "0".charCodeAt(0);
      if ((i & 1) === 1) {
        n *= 2;
        if (n > 9)
          n -= 9;
      }
      s += n;
    }
    if (s % 10 !== 0) {
      resp.code = -6;
      resp.message = 'Codice Fiscale Non Valido';
    }
    return resp;
  },


  /**
   * Verifies the basic syntax, length and control code of the given CF.
   * @param string cf Raw CF, possibly with spaces.
   * @return string Null if valid, or string describing why this CF must be
   * rejected.
   * 
   * Usa questo come entry point
   */
  validateCF(cf) {
    let resp = { code: 1, message: '' }

    if (cf.length === 0) {
      resp.code = -1;
      resp.message = "Codice Fiscale Vuoto";
    }
    else if (cf.length === 16)
      resp = this.validate_regular_cf(cf);
    else if (cf.length === 11)
      resp = this.validate_temporary_cf(cf);
    else {
      resp.code = -2;
      resp.message = "Lunghezza Codice Fiscale non valida";
    }
    return resp;
  },
  validateEmail(mail) {
    let resp = {};
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      resp.code = 1;
      
    }
    else
    {resp.code = -1; resp.message = "Email Non Valida";}
  return resp;
  }


}

export default mj_anagrafica_utils;