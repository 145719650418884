/*

SCOPO: Mostra i dati base di un account (profilo)
- Email, Password, Avatar e Nome
e si occupa di gestire le operazioni crud con il b.e.

Campi addizionali possono essere aggiunti tramite lo slot additional_fields.
Sul parent è possibile utilizzare come v-model i dati presenti nel form presente in questo componente.
Ma.. bisogna prima inizializzarli passandoli tramite la prop additionalFormFields.
Sul created tale prop verrà considerata per ampliare i dati presenti in form.

Esempio di utilizzo dello slot, nel parente, per aggiungere il campo cognome

<mj-my-account :additional-form-fields="{cognome:{type:'string',val:''}}">
<template v-slot:additional_fields="{ form }">
  <v-text-field label="cognome" v-model='form["cognome"]["val"]'>
  </v-text-field>
</template>
</mj-my-account>

TODO:

-----------------------------------------------------
HTTP: il componente si occupa di gestire tutte le comunicazioni http con il backend
-----------------------------------------------------

*/
<template>
  <v-container>

    <MjEntityForm  :form="form" :apiUrl="apiUrl">
      <template #fields="slotProps">


      <v-row>
        <v-col cols="12" md="8">
          <v-text-field v-model="form.email.val" :rules="[rules.required, rules.email]" label="Email"></v-text-field>
          <v-text-field
              v-model="form.password.val"
              type="password"
              :rules="slotProps.editMode ? [] : [rules.required, rules.min(8)]"
              label="Password"
              autocomplete='off'
              persistent-hint
              hint="Password non registrata nel sistema per motivi di sicurezza. Se vuoi cambiarla scrivi la nuova password nel campo di testo."
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" align-self="center">

          <mj-image :text="form.name.val[0]"
                    :image-src="form.avatar.val"
                    @img-changed="updateAvatar"
                    shape="circle"
                    circle_size="100"
          >
          </mj-image>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="form.name.val" label="Nome"></v-text-field>
        </v-col>
      </v-row>
      <slot name='additional_fields' :form="form">
      </slot>

      </template>
    </MjEntityForm>


  </v-container>
</template>

<script>
import formMixin from "@/mixins/FormMixin.js";
import MjEntityForm from "@/components/form/MjEntityForm.vue";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjImage from "@/components/form/MjImage.vue";

export default {
  name: "MjMyAccount",
  mixins: [formMixin, ValidationRulesMixin],
  components: {MjEntityForm, MjImage},
  props: ['additionalFormFields'],
  created() {

    //assegno al route.params.id l'id dell'utente per uniformarmi al funzionamento della schedamixin che utilizza l'id dell'extrapath (esempio con id 12 -> ../entity/12)
    this.$route.params.id = this.form.id.val = this.$store.state.token_obj.id;

    if (this.additionalFormFields) {
      Object.assign(this.form, this.additionalFormFields);
    }
  },
  data: () => ({
    //form può essere ampliato dallo slot additional fields
    form: {
      id: {type: "integer", val: ""},
      name: {type: "string", val: ""},
      email: {type: "string", val: ""},
      password: {type: "string", val: ""},
      avatar: {type: "string", val: ""}
    },
    apiUrl: "/api/user/users"
  }),

  methods: {

    updateAvatar(img_data) {
      this.form.avatar.val = img_data;
    },
    saveItem: function () {
      //ovverride + metodi aggiuntivi
      formMixin.methods.saveItem.call(this).then(() => {
        this.$store.commit("update_avatar", this.form.avatar.val);
      });
    },
  }
};
</script>
