/**
Utilizzato per mostare l'icona e relativo colore per un determinato valore di una MjEnum
prende in input il valore, e l'url da cui caricare la MjEnum (nel formato asObjectArray presente nel b.e.)
Per evitare molteplici caricamenti (ad esempio diverse righe di una tabella) viene usata una cache.
**/

<template>
  <v-sheet class="transparent">
    <v-tooltip v-if="currentStatus" top>
      <template v-slot:activator="{on,attrs}">
        <v-icon v-bind="attrs" :large="large" :x-large="xLarge" :small="small"
                v-on="on"
                :color="currentStatus['text_color']"
                @click="$emit('click')"

        >

          {{ currentStatus["icon"] }}
        </v-icon>
      </template>
      <span>{{ currentStatus['text'] }}</span>
    </v-tooltip>
    <v-progress-circular v-if="loading>0" indeterminate></v-progress-circular>
  </v-sheet>
</template>

<script>
import mj_axios from "@/mj_axios";

export default {
  props: ["value", "loadItemsUrl", "itemsProp", "large", "xLarge", "small"],
  name: "MjEnumIcon",
  mounted() {
    this.loadItems();
    if (this.itemsProp) {
      this.manageLoadItemsResponse(this.itemsProp);
    }
  },
  data: function () {
    return {
      items: this.itemsProp,
      currentStatus: null,
      cacheExpireSeconds: 10 * 1000,
      loading: 0,
    }
  },
  methods: {
    loadItems: function () {

      return new Promise((resolve, reject) => {
        if (this.loadItemsUrl) {

          let cachedResponse = JSON.parse(window.localStorage.getItem('mjEnumIcon' + this.loadItemsUrl));
          if (cachedResponse && ((cachedResponse.updatedDate + this.cacheExpireSeconds) > new Date().getTime())) {

            this.manageLoadItemsResponse(cachedResponse.items);
          } else {
            this.loading++;
            mj_axios
                .get(this.loadItemsUrl)
                .then((response) => {
                  this.items = response.data;
                  this.manageLoadItemsResponse();
                  window.localStorage.setItem('mjEnumIcon' + this.loadItemsUrl, JSON.stringify({
                    updatedDate: new Date().getTime(),
                    items: this.items
                  }));
                  resolve();
                })
                .catch(() => {
                  this.error_text = "Errore nel caricamento dati";
                  reject();
                })
                .then(() => {
                  this.loading--;
                });
          }
        } else {
          resolve();
        }
      });
    },
    manageLoadItemsResponse: function () {

      if (this.items && this.items.length > 0) {
        this.currentStatus = this.items.find((item) => item.value === this.value);
      } else {
        this.currentStatus = null
      }



    }
  },
  watch: {
    "value": function ( ) {
      this.manageLoadItemsResponse();
    },
    "itemsProp": function (itemsProp) {
      this.items = itemsProp;
      this.manageLoadItemsResponse();
    }
  }
}
</script>

<style scoped>

</style>