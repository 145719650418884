import mj_axios from "@/mj_axios";

const carrierMixin = {

    data: function () {
        return {
            carriers: [],
            loadingCarriers: 0,
        };
    },

    methods: {
        loadCarriers() {
            this.loadingCarriers++;
            const axios_promise = mj_axios.get('/api/carriers');
            axios_promise.then(response => {
                this.carriers = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento tipi imballo, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingCarriers--;
            });
            return axios_promise;
        },
    },

};

export default carrierMixin;
