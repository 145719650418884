import PlanningRequest from "@/views/planning/PlanningRequest";
import ShipmentsMonitorView from "@/views/monitor/ShipmentsMonitorView";

import TableShipments from "@/views/shipments/TableShipments";
import ProductionPlantFormView from "@/views/forms/subjects/ProductionPlantFormView";
import CarrierFormView from "@/views/forms/subjects/CarrierFormView";
import DestinationPlantFormView from "@/views/forms/subjects/DestinationPlantFormView";
import ProductTypeFormView from "@/views/forms/types/ProductTypeFormView";
import VehicleTypeFormView from "@/views/forms/types/VehicleTypeFormView";
import PackagingTypeFormView from "@/views/forms/types/PackagingTypeFormView";
import IntermediaryFormView from "@/views/forms/subjects/IntermediaryFormView";
import TableProductionPlants from "@/views/tables/TableProductionPlants";
import TableCarriers from "@/views/tables/TableCarriers";
import TableDestinationPlants from "@/views/tables/TableDestinationPlants";
import ShipmentFormView from "@/views/forms/ShipmentFormView";
import ShipmentTariffsView from "@/views/admin/ShipmentTariffsView";
import MyProductionPlants from "@/views/planning/MyProductionPlants";
import PlanningShipments from "@/views/planning/PlanningShipments";
import PlanningShipmentProductReport from "@/views/reports/PlanningShipmentProductReport";
import PlanningShipmentsDetail from "@/views/planning/PlanningShipmentsDetail";
import AntheaCodesView from "@/views/admin/AntheaCodesView";


export  const custom_routes = [
    //CRUD
    { path: '/form_production_plant', name: 'NewProductionPlantFormView', component: ProductionPlantFormView, meta: { title: 'Scheda Impianto Produzione', role: ['SuperAdmin','Admin'] } },
    { path: '/form_production_plant/:id', name: 'ProductionPlantFormView', component: ProductionPlantFormView, meta: { title: 'Scheda Impianto Produzione', role: ['SuperAdmin','Admin'] } },
    { path: '/form_carrier', name: 'NewCarrierFormView', component: CarrierFormView, meta: { title: 'Scheda Trasportatore', role: ['SuperAdmin','Admin'] } },
    { path: '/form_carrier/:id', name: 'CarrierFormView', component: CarrierFormView, meta: { title: 'Scheda Trasportatore', role: ['SuperAdmin','Admin'] } },
    { path: '/form_destination_plant', name: 'NewDestinationPlantFormView', component: DestinationPlantFormView, meta: { title: 'Scheda Impianto di Destinazione', role: ['SuperAdmin','Admin'] } },
    { path: '/form_destination_plant/:id', name: 'DestinationPlantFormView', component: DestinationPlantFormView, meta: { title: 'Scheda Impianto di Destinazione', role: ['SuperAdmin','Admin'] } },
    { path: '/form_product_type', name: 'NewProductTypeFormView', component: ProductTypeFormView, meta: { title: 'Scheda Tipo Prodotto', role: ['SuperAdmin','Admin'] } },
    { path: '/form_product_type/:id', name: 'ProductTypeFormView', component: ProductTypeFormView, meta: { title: 'Scheda Tipo Prodotto', role: ['SuperAdmin','Admin'] } },
    { path: '/form_vehicle_type', name: 'NewVehicleTypeFormView', component: VehicleTypeFormView, meta: { title: 'Scheda Tipo Veicolo', role: ['SuperAdmin','Admin'] } },
    { path: '/form_vehicle_type/:id', name: 'VehicleTypeFormView', component: VehicleTypeFormView, meta: { title: 'Scheda Tipo Veicolo', role: ['SuperAdmin','Admin'] } },
    { path: '/form_packaging_type', name: 'NewPackagingTypeFormView', component: PackagingTypeFormView, meta: { title: 'Scheda Tipo Confezionamento', role: ['SuperAdmin','Admin'] } },
    { path: '/form_packaging_type/:id', name: 'PackagingTypeFormView', component: PackagingTypeFormView, meta: { title: 'Scheda Tipo Confezionamento', role: ['SuperAdmin','Admin'] } },
    { path: '/form_intermediary', name: 'NewIntermediaryFormView', component: IntermediaryFormView, meta: { title: 'Scheda Intermediary', role: ['SuperAdmin','Admin'] } },
    { path: '/form_intermediary/:id', name: 'IntermediaryFormView', component: IntermediaryFormView, meta: { title: 'Scheda Intermediary', role: ['SuperAdmin','Admin'] } },
    { path: '/form_shipment', name: 'NewShipmentFormView', component: ShipmentFormView, meta: { title: 'Scheda Shipment', role: ['SuperAdmin','Admin'] } },
    { path: '/form_shipment/:id', name: 'ShipmentFormView', component: ShipmentFormView, meta: { title: 'Scheda Shipment', role: ['SuperAdmin','Admin'] } },


    //TABLEs OVERRIDE
    {path: '/table/ProductionPlant', component: TableProductionPlants, meta: {title: 'Gestione Impianti Produzione', role: ['SuperAdmin', 'Admin']}},
    {path: '/table/DestinationPlant', component: TableDestinationPlants, meta: {title: 'Gestione Impianti Destinazione', role: ['SuperAdmin', 'Admin']}},
    {path: '/table/Carrier', component: TableCarriers, meta: {title: 'Gestione Trasportatori', role: ['SuperAdmin', 'Admin']}},
    {path: '/table/Shipment', component: TableShipments, meta: {title: 'Storico Viaggi', role: ['SuperAdmin', 'Admin']}},

    //PLANNING
    {path: '/planning/my_production_plants', component: MyProductionPlants, meta: {title: 'Richiesta Carichi', role: ['SuperAdmin', 'Admin','Produttore']}},
    {path: '/planning/request/:productionPlantId', component: PlanningRequest, meta: {title: 'Richiesta Carichi', role: ['SuperAdmin', 'Admin','Produttore']}},
    {path: '/planning/shipment', component: PlanningShipments, meta: {title: 'Pianificazione Viaggi', role: ['SuperAdmin', 'Admin','Logistica']}},
    {path: '/planning/shipment_detail', component: PlanningShipmentsDetail, meta: {title: 'Dettaglio ', role: ['SuperAdmin', 'Admin','Logistica']}},
    {path: '/planning/product_type_report', component: PlanningShipmentProductReport, meta: {title: 'Report per Prodotto ', role: ['SuperAdmin', 'Admin','Logistica']}},

    //MONITOR
    {path: '/monitor/shipments', component: ShipmentsMonitorView, meta: {title: 'Monitor Viaggi', role: ['SuperAdmin', 'Admin','PostazionePesa','Accettazione','Logistica','Produttore']}},

    //TARIFF
    {path: '/admin/shipment_tariffs', component: ShipmentTariffsView, meta: {title: 'Tariffario', role: ['SuperAdmin', 'Admin']}},

    //ANTHEA
    {path: '/admin/anthea_codes', component: AntheaCodesView, meta: {title: 'Codici Anthea', role: ['SuperAdmin', 'Admin']}},
];
