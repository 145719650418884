<template>
        <v-badge overlap >
          <span slot="badge">{{ count }}</span>
          <span slot="default">
            <MjEnumIcon @click="loadShipmentCount" :value='status' :items-prop="shipmentStatusMjEnum"></MjEnumIcon></span>
        </v-badge>
</template>

<script>

import ShipmentStatusMjEnumMixin from "@/mixins/entities/ShipmentStatusMjEnumMixin";
import MjEnumIcon from "@/components/MjEnumIcon";
import mj_axios from "@/mj_axios";

export default {
  name: "ShipmentCounter",
  components: {MjEnumIcon},
  mixins:[ShipmentStatusMjEnumMixin],
  props:{
    status:{}, weekId:{},
  },
  created() {
    this.loadShipmentStatusMjEnum();
  },
  mounted() {
    if (this.weekId)  {this.loadShipmentCount();}
  },
  data: function () {
    return {
      count:'-',
      loading:0,
    }
  },
  methods: {
    loadShipmentCount(){

        this.loading++;
        this.count = '-';
        const axios_promise = mj_axios.get(`/api/shipments_count?weekId=${this.weekId}&status=${this.status}`);
        axios_promise.then(response => {
          this.count = response.data.count;
        }).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
            visible: true,
          });
        }).then(() => {
          this.loading--;
        });
        return axios_promise;

    }

  },
  watch:{
    status:{
      handler:function(){
       if (this.weekId) {this.loadShipmentCount();}
      }
    },
    weekId:{
      handler:function(){
        if (this.weekId) {this.loadShipmentCount();}
      }
    }
  }
}
</script>

<style scoped>

</style>