<template>
  <v-container>
    <MjEntityForm :title="'Intermediario ' + (($route.params.id) ? $route.params.id : 'Nuovo') " :form="form"
                  :apiUrl="apiUrl">
      <!--eslint-disable-next-line vue/no-unused-vars-->
      <template #fields="slotProps">
        <v-row>
          <v-col cols='12' md='3'>
            <v-text-field
                label='Alias'
                v-model='form.cod.val'
                :rules="[rules.required,rules.max(6)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='8'>
            <v-text-field
                label='Nome Intermediario'
                v-model='form.name.val'
                :rules="[rules.max(64)]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='6'>
            <v-text-field
                label='Email'
                v-model='form.email.val'
                :rules="[rules.max(64)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <v-text-field
                label='Tel'
                v-model='form.tel.val'
                :rules="[rules.max(24)]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-text-field
                label='Info Referente'
                v-model='form.reference_person_info.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Note'
                v-model='form.note.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
        </v-row>
      </template>
    </MjEntityForm>
  </v-container>
</template>

<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjEntityForm from "@/components/form/MjEntityForm";


export default {
  name: "IntermediaryFormView",
  mixins: [ValidationRulesMixin],
  components: {MjEntityForm,},
  data: () => ({
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      name: {type: 'string', val: '', default_val: ''},
      reference_person_info: {type: 'string', val: '', default_val: ''},
      email: {type: 'string', val: '', default_val: ''},
      tel: {type: 'string', val: '', default_val: ''},
      note: {type: 'text', val: '', default_val: ''},
    },
    apiUrl: "/api/intermediaries"
  }),
};
</script>
