<template>
  <v-container>
    <MjEntityForm :title="'Impianto di Destinazione ' + (($route.params.id) ? $route.params.id : 'Nuovo') " :form="form"
                  :apiUrl="apiUrl">
      <!--eslint-disable-next-line vue/no-unused-vars-->
      <template #fields="slotProps">
        <v-row>
          <v-col cols='12' md='2'>
            <v-text-field
                label='Alias'
                v-model='form.cod.val'
                :rules="[rules.required,rules.max(6)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='3'>
            <v-text-field
                label='Nome'
                v-model='form.name.val'
                :rules="[rules.max(64)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Email'
                v-model='form.email.val'
                :rules="[rules.max(64)]">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="1"><v-switch v-model="form.status.val" :true-value="1" :false-value="0" label="Destinazione Attiva"></v-switch></v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='8'>
            <v-text-field
                label='Persona Riferimento'
                v-model='form.reference_person_info.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='8'>
            <v-text-field
                label='Tel'
                v-model='form.tel.val'
                :rules="[rules.max(24)]">
            </v-text-field>
          </v-col>

        </v-row>
        <v-row>
          <v-col md="6">
            <v-text-field
                label='Giorni Consegna'
                hint="Numero di giorni necessari per la consegna"
                v-model='form.delivery_days_count.val'
                :rules="[rules.integer, rules.required]">
            </v-text-field>
          </v-col>
          <v-col md="6">
            <v-switch v-model="form.strict_delivery_time.val" label="Rispetto Orario" :true-value="1" :false-value="0"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea

                label='Note'
                v-model='form.note_for_carrier.val'
                rows='2'
            >
            </v-textarea>


          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
                prepend-icon="mdi-package-variant"
                label='Tipo prodotti'
                chips
                deletable-chips
                multiple
                v-model='form.productTypesIds.val'
                item-text="cod" item-value="id" :items="slotProps.selects.productTypesIds" cache-items
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
                prepend-icon="mdi-account-tie"
                label='Intermediari'
                chips
                deletable-chips
                multiple
                v-model='form.intermediariesIds.val'
                item-text="cod" item-value="id" :items="slotProps.selects.intermediariesIds" cache-items
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Note'
                v-model='form.note.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
        </v-row>

      </template>
    </MjEntityForm>
  </v-container>
</template>

<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjEntityForm from "@/components/form/MjEntityForm";


export default {
  name: "DestinationPlantFormView",
  mixins: [ValidationRulesMixin],
  components: {MjEntityForm,},
  data: () => ({
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      name: {type: 'string', val: '', default_val: ''},
      email: {type: 'string', val: '', default_val: ''},
      note: {type: 'text', val: '', default_val: ''},
      status: {type: 'boolean', val: '', default_val: ''},

      delivery_days_count: {type: 'integer', val: '', default_val: '0'},
      strict_delivery_time: {type: 'boolean', val: '', default_val: '0'},

      reference_person_info: {type: 'string', val: '', default_val: ''},
      tel: {type: 'string', val: '', default_val: ''},
      note_for_carrier: {type: 'text', val: '', default_val: ''},

      intermediariesIds: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/intermediaries'
      },
      productTypesIds: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/product_types'
      },
    },
    apiUrl: "/api/destination_plants"
  }),
};
</script>
