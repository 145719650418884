<template>
  <v-container fluid>
    <v-row align="center" class="mt-2">
      <v-col>
        <v-text-field type="number" v-model.number="shipmentCount" label="N° Viaggi"></v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete label="Intermediario" prepend-inner-icon="mdi-account-tie"
                        :loading="loadingDestinationPlantsIntermediaries > 0"
                        v-model="selectedIntermediary" :items="destinationPlantsIntermediaries" item-value="id"
                        item-text="cod"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field disabled reverse prepend-inner-icon="mdi-currency-eur" label="Costo tot."
                      :value="selectedShipmentTariff  ? mj_string_utils.moneyFormat(shipmentCount * selectedShipmentTariff.price) : 0">
        </v-text-field>
      </v-col>
      <v-col>
        <v-btn color="primary" @click="planShipment" :disabled="!selectedShipmentTariff" :loading="insertingShipment > 0">
          <v-icon>mdi-check</v-icon>
          Assegna e Pianifica
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" class="mt-2">
      <v-col cols="12" sm="6">
        <v-textarea v-model="internalNote" rows="2" placeholder="Nota Interna"></v-textarea>
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea v-model="note" rows="2" placeholder="Nota Trasportatore"></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IntermediaryMixin from "@/mixins/entities/IntermediaryMixin";
import mj_string_utils from "@/misc/mj_string_utils";
import mj_date_utils from "@/misc/mj_date_utils";
import ShipmentMixin from "@/mixins/entities/ShipmentMixin";

export default {
  name: "ShipmentInput",
  mixins: [IntermediaryMixin, ShipmentMixin],
  props: {
    selectedShipmentTariff: {type: Object},
    weekId: {type: String},
    dayIndex: {type: Number},
    productionPlantId: {}
  },
  computed: {
    computedLoadingDate() {
      return mj_date_utils.getMomentDate(this.weekId, this.dayIndex);
    }
  }
  ,
  created() {

  },
  data:
      function () {
        return {
          mj_string_utils: mj_string_utils,
          selectedIntermediary: null,
          shipmentCount: 1,
          internalNote: null,
          note: null,
          destinationPlantsIntermediaries: null,
        }
      },
  methods: {
    planShipment() {

      const shipment = {
        status: 'DRAFT',
        destination_plant_id: this.selectedShipmentTariff?.destinationPlant?.id,
        product_type_id: this.selectedShipmentTariff?.productType?.id,
        carrier_id: this.selectedShipmentTariff?.carrier?.id,
        vehicle_type_id: this.selectedShipmentTariff?.vehicleType?.id,
        packaging_type_id: this.selectedShipmentTariff?.packagingType?.id,
        loading_date: this.computedLoadingDate.format('YYYY-MM-DD'),
        intermediary_id: this.selectedIntermediary,
        production_plant_id: this.productionPlantId,
        shipment_count: this.shipmentCount,
        internal_note: this.internalNote,
        price:this.selectedShipmentTariff?.price,
        note: this.note,
      };


      this.insertShipment(shipment).then(() =>
          this.$emit("shipmentPlanned", {
            intermediary: this.selectedIntermediary,
            tariff: this.selectedShipmentTariff,
            shipmentCount: this.shipmentCount
          }));


    }
  },
  watch: {
    selectedShipmentTariff: {
      deep: true,
      handler: function () {
        if (this.selectedShipmentTariff.destinationPlant)
          this.loadDestinationPlantIntermediaries(this.selectedShipmentTariff.destinationPlant.id);
        else
          this.destinationPlantsIntermediaries = null;
      }
    }
  }

}
</script>

<style scoped>

</style>