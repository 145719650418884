<template>
  <v-container fill-height>
    <v-card class="mx-auto grey lighten-5 p-5" max-width="480px" width="75vw" elevation="2" rounded>
      <MjAlreadyLoggedDialog></MjAlreadyLoggedDialog>
      <v-card-title ><div class="mx-auto subtitle-1 font-weight-light grey--text text--darken-2  "> Accedi a <b class="font-weight-bold">{{
          this.$store.state.client_settings.APPNAME
        }}</b></div>
      </v-card-title>
      <v-card-text class="px-12 mb-12">
        <MjLogin/>
      </v-card-text>

      <MjGoogleLoginButton v-if="googleLoginOn"/>

      <v-row>
        <MjFacebookLoginButton v-if="facebookLoginOn" class="text-center my-4"/>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import store from "@/store.js";
import mj_config from "@/mj_config.js";
import MjLogin from "@/components/MjLogin.vue"
import MjAlreadyLoggedDialog from "@/components/MjAlreadyLoggedDialog.vue"
import MjGoogleLoginButton from "@/components/social/MjGoogleLoginButton.vue";
import MjFacebookLoginButton from "@/components/social/MjFacebookLoginButton.vue";

export default {
  name: "LoginView",
  components: {MjGoogleLoginButton, MjLogin, MjFacebookLoginButton, MjAlreadyLoggedDialog},
  store, mj_config,
  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build

  computed: {
    access_token: function () {
      return store.state.token_obj.access_token;
    },
    googleLoginOn: () => mj_config.googleLoginOn(),
    facebookLoginOn: () => mj_config.facebookLoginOn(),
  },
  data: () => ({
    showLogged: true,
    show_error: false,
    login_error_text: "",
    valid: true,
    showPassword: false,
    form: {
      password: "",
      email: ""
    },
    passwordRules: [
      v => !!v || "Password Obbligatora is required",
      v => (v && v.length >= 8) || "Digita almeno 8 caratteri"
    ],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    lazy: false
  }),

  methods: {
    submit() {
      this.show_error = false;
      this.login_error_text = "";
      if (this.$refs.form.validate()) {
        const user = {
          email: this.form.email,
          password: this.form.password
        };

        store
            .dispatch("login", user)
            .then(() => {
              this.$router.replace("/menu");
            })
            .catch(e => {
              this.show_error = true; //usa arrow function per avere il this del componente
              this.login_error_text = e.response.data.message;
            });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    logout() {
      this.$store.dispatch("logout").finally(() => {
        this.$router.replace("/utente/login");
      });
    }
  },
  watch: {
    form: {
      handler: function () {
        this.show_error = false;
        return true;
      },
      deep: true
    }
  }
};
</script>
