<template>
  <v-container>
    <MjTableItems
        ref="table_users"
        model-name-prop="User"
        resource-collection-name="UserResourceListCollection"
    >

      <template v-if="user_has_credit_enabled" v-slot:[`custom_actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click.stop="selected_row = item;show_user_credit_dialog=true"
                   class="x-small secondary ml-2 mr-4" icon
                   elevation="3">
              <v-icon>mdi-plus-minus-variant</v-icon>
            </v-btn>
          </template>

          Gestione Credito Utente
        </v-tooltip>
      </template>
    </MjTableItems>

    <v-dialog v-if="selected_row" v-model="show_user_credit_dialog" max-width="600px">
      <v-card>
        <v-card-title>Credito Utente</v-card-title>
        <v-card-text>

          <h5>Utente: {{ selected_row.Email }}</h5>
          <h4>Il credito attuale: {{ selected_row.Credito }}</h4>
          <v-form ref="update_credit_form" :lazy-validation="true">
            <v-text-field style="font-size:x-large; " height="60" label="Importo" v-model="dialog_credit_transaction_amount"
                          :rules="[rules.required, rules.integer, rules.positive]"/>
            <v-text-field label="Descrizione Movimento" v-model="dialog_credit_transaction_description" hint="(opzionale) Descrizione breve del movimento"></v-text-field>
            <v-textarea rows="2" label="Nota Movimento" v-model="dialog_credit_transaction_note" hint="(opzionale) Nota del movimento"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="dialog_sub_loading>0" class="red" right @click="updateCredit(true)">Addebita
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn :loading="dialog_add_loading>0" class="green" right @click="updateCredit(false)">Accredita
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>

</style>
<script>

import MjTableItems from "@/components/MjTableItems";

import mj_config from '@/mj_config';
import mj_axios from "@/mj_axios";
import validationRulesMixin from "@/mixins/ValidationRulesMixin";

export default {
  name: "UsersTableView",
  mixins: [validationRulesMixin],
  components: {MjTableItems,},
  data: () => ({
    selected_row: null,
    show_user_credit_dialog: false,
    dialog_credit_transaction_amount: 0,
    dialog_sub_loading: false,
    dialog_add_loading: false,
    dialog_credit_transaction_description: '',
    dialog_credit_transaction_note: '',
  }),
  computed: {
    user_has_credit_enabled() {
      return mj_config.user_has_credit_enabled();
    }
  },
  methods: {

    updateCredit: function (substract) {

      let amount = 0;
      if (this.$refs.update_credit_form.validate()) {
        if (substract) {
          this.dialog_sub_loading++;
          amount = (this.dialog_credit_transaction_amount) < 0 ? this.dialog_credit_transaction_amount : -1 * this.dialog_credit_transaction_amount;
        } else {
          amount = (this.dialog_credit_transaction_amount) > 0 ? this.dialog_credit_transaction_amount : -1 * this.dialog_credit_transaction_amount;
          this.dialog_add_loading++;
        }
        const params = {"amount": amount,'description':this.dialog_credit_transaction_description, 'note': this.dialog_credit_transaction_note};
        const axios_promise = mj_axios.post(`/api/user/update_user_credit/${this.selected_row['id']}`, params);
        axios_promise.then(() => {

        }).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Errore di aggiornamento credito utente.",
            visible: true,
          });
        }).then(() => {
          this.show_user_credit_dialog = false;
          (substract) ? this.dialog_sub_loading-- : this.dialog_add_loading--;
          this.$store.dispatch('reloadLoggedUser');
          this.$refs.table_users.loadItems(false);
        });
        return axios_promise;
      }
    }

  },
  watch: {}
};
</script>
