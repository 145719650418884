<!-- Set di autocomplete per la scelta dei filtri per pianificare un viaggio-->
<template>
  <v-row>
    <v-col cols="12" sm="3">
      <v-autocomplete label="Trasportatore" prepend-inner-icon="mdi-truck-outline" :loading="loadingCarriers > 0" clearable
                      :disabled="selectedValues.carrier?.disabled"
                      v-model="selectedCarrier" :items="carriers" item-value="id" item-text="cod" return-object
                      @change="handleChange"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="2">
      <v-autocomplete label="Mezzo" prepend-inner-icon="mdi-truck-trailer" :loading="loadingVehicleTypes > 0" clearable
                      :disabled="selectedValues.vehicleType?.disabled"
                      v-model="selectedVehicleType" :items="vehicleTypes" item-value="id" item-text="cod" return-object
                      @change="handleChange">
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="3">
      <v-autocomplete label="Imballo" prepend-inner-icon="mdi-package-variant" :loading="loadingPackagingTypes > 0"
                      clearable
                      v-model="selectedPackagingType" :items="packagingTypes" item-value="id" item-text="cod"
                      return-object
                      :disabled="selectedValues.packagingType?.disabled"
                      @change="handleChange">
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="4">
      <v-autocomplete label="Imp. Destinazione" prepend-inner-icon="mdi-factory" :loading="loadingDestinationPlants > 0" clearable
                      :disabled="selectedValues.destinationPlant?.disabled"
                      v-model="selectedDestinationPlant" :items="destinationPlants" item-value="id" item-text="cod"
                      return-object
                      @change="handleChange">
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import CarrierMixin from "@/mixins/entities/CarrierMixin";
import VehicleTypeMixin from "@/mixins/entities/VehicleTypeMixin";
import DestinationPlantMixin from "@/mixins/entities/DestinationPlantMixin";
import PackagingTypeMixin from "@/mixins/entities/PackagingTypeMixin";
import ReactivityMixin from "@/mixins/ReactivityMixin";

export default {
  name: "TariffFilters",
  mixins: [CarrierMixin, VehicleTypeMixin, DestinationPlantMixin, PackagingTypeMixin, ReactivityMixin],
  props: {selectedValues: {type: Object}},
  created() {
    this.loadCarriers();
    this.loadVehicleTypes();
    this.loadDestinationPlants();
    this.loadPackagingTypes();

  },
  data: function () {
    return {
      selectedCarrier: this.selectedValues.carrier,
      selectedVehicleType: this.selectedValues.vehicleType,
      selectedPackagingType: this.selectedValues.packagingType,
      selectedDestinationPlant: this.selectedValues.destinationPlant,
      selectedProductType: this.selectedValues.productType, //read.only
    }
  },
  methods: {
    handleChange() {
      this.$emit('update:selectedValues',
          {
            "productType": this.selectedValues.productType,
            "carrier": this.selectedCarrier,
            "vehicleType": this.selectedVehicleType,
            "packagingType": this.selectedPackagingType,
            "destinationPlant": this.selectedDestinationPlant
          },
      );
    },
  },
  watch: {
    selectedValues: {
      deep: true,
      handler: function (selectedValues) {
        this.selectedCarrier = selectedValues.carrier;
        this.selectedVehicleType = selectedValues.vehicleType;
        this.selectedPackagingType = selectedValues.packagingType;
        this.selectedDestinationPlant = selectedValues.destinationPlant;
      }
    }
  }


}
</script>

<style scoped>

</style>