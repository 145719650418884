/*

SCOPO: Mostrare in un popup o dialog una lista di elementi selezionabili da associare ad altri elementi
-----------------------------------------------------
BACKEND: la relazione gestita è molti a molti.
-----------------------------------------------------
HTTP: il componente si occupa di gestire tutte le comunicazioni http con il backend
-----------------------------------------------------
Supponiamo una (o più) attrezzatura che possiede diversi programmi di manutenzione.
Sul componente Padre (quello che ingloba e usa la MjSelectableList) seleziono uno o più attrezzature (parents_id props)
Questi id vengono passati dal padre verso il figlio usando le props.

LOAD:
1) Devo caricare gli items (children) da mostrare all'utente usando la props "load_items_url"
2) Devo caricare le associazioni items -> children e aggiornare le select di ogni item.

SAVE:

1) costruisco un obj di request passando i parents id e
2) per ogni children indico lo stato di selezione (true, indeterminate, false)
Per l'algoritmo di salvataggio vedi il b.e.


*/

<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <v-text-field append-icon="mdi-magnify" @input="updateSearchInput"></v-text-field>

      <v-list :max-height="max_height" style="overflow:auto">
        <v-list-item v-if="showLoader">
          <transition name="fade">
            <v-progress-linear indeterminate rounded height="16"></v-progress-linear>
          </transition>
        </v-list-item>

        <v-list-item class="danger" v-if="error_text.length > 0 ">
          <v-list-item-icon>
            <v-icon>mdi-alert-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="this.error_text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-for="(item,i) in items">
          <v-list-item :key="i" v-show="item.visible">
            <!-- <template v-slot:default="{selected}"> -->
            <v-list-item-action>
              <v-checkbox
                  :input-value="item['selected']"
                  :indeterminate="item['selected'] === 'indeterminate'"
                  @click="checkboxClick(item,i,item['selected'])"
                  color="primary"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item[list_title_field]"></v-list-item-title>
              <v-list-item-subtitle v-text="item[list_subtitle_field]"></v-list-item-subtitle>
            </v-list-item-content>
            <!-- </template> -->
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn v-show="this.items.length > 0" @click="saveRelations">
        <v-icon>mdi-check</v-icon>
      </v-btn>

      <v-btn @click="$emit('close-popup')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import mj_axios from "@/mj_axios.js";

export default {
  name: "MjSelectableList",
  props: {
    "title": {default:()=>'Selectable List'},
    "value": {},
    "exclusive": {default: () => false},
    "load_items_url": {},
    "belong_url": {},
    "pivot_table": {},
    "parent_col_name": {},
    "child_col_name": {},
    "parent_model_name": {},
    "child_model_name": {},
    "relation_name": {},
    "list_title_field": {},
    "list_subtitle_field": {},
    "max_height": {},
    "parent_ids": {},
  },
  data: () => ({
    items: [],
    showLoader: true,
    error_text: "",
  }),
  created() {


    this.loadItems().then(this.loadSelected);
  },
  methods: {
    updateSearchInput(val) {

      if (val) {
        this.items = this.items.map((item) => {
          // item.selected = false;
          if (
              item[this.list_title_field]
                  .toLowerCase()
                  .includes(val.toLowerCase()) ||
              item[this.list_subtitle_field]
                  .toLowerCase()
                  .includes(val.toLowerCase())
          ) {
            item.visible = true;
          } else {
            item.visible = false;
          }
          return item;
        });
      }
    },
    checkboxClick(item, index, selected) {

      if (this.exclusive) {
        this.unselectAllExcept(item);
      }

      item.selected = !selected;
      this.$set(this.items, index, item);
    },
    unselectAllExcept(item) {//unselect others
      this.items.forEach((itemInLoop, index) => {
        if (itemInLoop != item) {
          itemInLoop.selected = false;
          this.$set(this.items, index, itemInLoop);
        }
      });

    },
    saveRelations() {
      this.error_text = "";

      this.showLoader = true;
      let request_obj = {
        parent_ids: this.parent_ids,
        parent_model_name: this.parent_model_name,
        child_model_name: this.child_model_name,
        relation_name: this.relation_name
      };
      request_obj.children = [];
      this.items.forEach((item) => {
        if (item.selected !== "indeterminate") {
          //non mando quelli indeterminate perchè su di essi non bisogna fare nessuna query
          let obj = {};
          obj.id = item.id;
          obj.selected = item.selected;
          request_obj.children.push(obj);
        }
      });

      //on server side
      //scorro le chiavi
      //se true => insert if not exist le associazioni where parent_id in parents_ids and child_id = chiave
      //se false => delete le associazioni where parent_id in parents_ids and child_id = chiave
      //se indeterminate => non fare nulla. anzi a monte non mando tali dati


      const axios_promise = mj_axios.post(
          this.belong_url,
          JSON.stringify(request_obj)
      );

      axios_promise
          .then(() => {

            this.$emit("close-popup");
          })
          .catch((e) => {
            const status = e.response ? e.response.status : null;
            let message =
                e.response && e.response.data ? e.response.data.message : null;
            this.error_text = `Errore nel salvataggio dati ${message} status:${status}`;
          })
          .then(() => {
            this.showLoader = false;
          });
    },
    loadSelected() {


      //reset
      this.items.forEach((item) => {
        item.selected = false;
      });

      //programma 5 è collegato ad attrezzatura 1 etc.
      // let selected = { 5: [9088, 9089], 8: [9089], 9: [9088] };
      let selected = {};

      mj_axios
          .get(this.belong_url, {
            params: {
              parents_id: JSON.stringify(this.parent_ids),
              pivot_table: this.pivot_table,
              parent_col_name: this.parent_col_name,
              child_col_name: this.child_col_name
            },
          })
          .then((response) => {
            selected = response.data.data;

            this.items.forEach((item) => {
              const current_item = selected[item.id];
              if (current_item) {
                if (current_item.length == this.parent_ids.length) {
                  item.selected = true;
                } else {
                  item.selected = "indeterminate";
                }
              }
            });


          })
          .catch(() => {
            this.error_text = "Errore nel caricamento dati";
          })
          .then(() => {
            this.showLoader = false;
          });
    },
    loadItems() {
      return new Promise((resolve, reject) => {
        mj_axios
            .get(this.load_items_url)
            .then((response) => {
              const items = response.data.data;
              this.items = items.map((item) => {
                item.selected = false;
                item.visible = true;
                return item;
              });

              resolve();

              // for (let i = 0; i < 44; i++) this.items.push(this.items[0]);
            })
            .catch(() => {
              this.error_text = "Errore nel caricamento dati";
              reject();
            })
            .then(() => {
              this.showLoader = false;
            });
      });
    },
  },
  watch: {
    parent_ids: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        this.loadSelected();
      },
    },
  },
};
</script>