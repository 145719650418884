import mj_axios from "@/mj_axios";

const shipmentRequestMixin = {

    data: function () {
        return {
            shipmentRequests: null,
            savingShipmentRequests: 0,
            loadingShipmentRequests: 0,
        };
    },

    methods: {

        loadShipmentRequests(productionPlantId, weekId, productTypeId, dayIndex) {
            this.loadingShipmentRequests++;
            const axios_promise = mj_axios.get(`/api/production_plants/${productionPlantId}/shipment_requests?week_id=${weekId}&product_type_id=${productTypeId}&day_index=${dayIndex}`);
            axios_promise.then(response => {
                this.shipmentRequests = response.data;
                this.shipmentRequestChanged = false;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento richieste carichi settimanali, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingShipmentRequests--;
            });
            return axios_promise;
        },
        saveShipmentRequests(productionPlantId, weekId) {

            this.savingShipmentRequests++;
            this.shipmentRequests.weekId = weekId;
            const params = JSON.stringify(this.shipmentRequests);
            const axios_promise = mj_axios.post(`/api/production_plants/${productionPlantId}/shipment_requests`, params);
            axios_promise.then(() => {
                this.shipmentRequestChanged = false;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.savingShipmentRequests--;
            });
            return axios_promise;
        },
    },

};

export default shipmentRequestMixin;
