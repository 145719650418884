/**
 * Utilizzato per gestire la reattività degli oggetti
 */
const ReactivityMixin = {
    methods: {
        resetObject(objectName) {
            Object.keys(this[objectName]).forEach((key) => {
                this.$delete(this[objectName], key);
            });
        },
        updateObject(objectName, newObject) {
                Object.keys(newObject).forEach((key) => {
                    this.$set(this[objectName], key, newObject[key]);
                });
        },
        resetArray(arrayName) {
            this[arrayName] = [];

        },
        updateArray(arrayName, newArray) {
            (newArray).forEach((val, index) => {
                this.$set(this[arrayName], index, val);
            });
        }
    }
};

export default ReactivityMixin;