const mj_config = {
    isDevelopmentMode() {
        return (process.env.NODE_ENV === 'development')
    },
    getBaseUrl() {
        let baseUrl;
        if (this.isDevelopmentMode()) {
            baseUrl = `http://${window.location.hostname}:8787`;
        } else {
            baseUrl = `https://${window.location.hostname}`;
        }
        return baseUrl
    }
    ,
    //disabilitiamo o abilitiamo a livello globale il csv exporting
    csv_global_export_enabled() {
        return false;
    },
    //stabilisco il tema di default (dark vs light)
    dark_mode_on_default() {
        return false;
    },
    //considero il tema memorizzato nello storage o il valore di default
    dark_mode_on() {
        if (window.localStorage.getItem('dark_mode_on') === 'true') {
            return true;
        } else if (window.localStorage.getItem('dark_mode_on') === 'false') {
            return false;
        } else {
            return this.dark_mode_on_default();
        }
    },
    toc_url() {
        return 'blabla'
    },
    privacy_url() {
        return 'blabla'
    },
    //indica se il sistema prevedere registrazione, recupero password.
    allow_registration() {
        return false;
    },
    //indica se l'utente ha un credito (ad es. prepagato)
    user_has_credit_enabled() {
        return false;
    },
    facebookLoginOn() {
        return false;
    },
    facebookAppId() {
        return 'blabla';
    },
    facebookApiVer() {
        return "v13.0";
    },
    googleLoginOn() {
        return false;
    },
    googleClientId() {
        return "blabla";
    },
    googleSiteKey() {
        return '6Lf4suEUAAAAANoI6Wva8n37yfYOZa5RAuegEtRE';
    }, //usato per recaptcha
    stripePublicKey() {
        if (this.isDevelopmentMode())
            return 'blabla';
        else
            return 'blabla';
    },
    defaultTableItemsOpenRowTarget(){
        return 'same';
    },
    mjTableItemsPerPage(){return 200},
    backOnSave() { return true;}
}
export default mj_config;
