<template>
  <v-container>
    <MjSoftTitle class="ml-3 mb-6"
                 :title="title">
    </MjSoftTitle>
    <MjSchedaToolbar
        class="mb-0"
        :error_show="error_show"
        :error_text="error_text"
        :edit_not_saved="edit_not_saved"
        :edit_mode="editMode"
        :new_button_visibile="true"
        :restore_item_show="restore_item_show"
        v-on:new-item-click="newItem()"
        v-on:save-item-click="saveItem()"
        v-on:go-back-click="goBack()"
        v-on:restore-item-click="restoreItem()"
    />
    <v-progress-linear indeterminate v-if="loading_overlay"></v-progress-linear>
    <v-card rounded max-height="73vh" :disabled="loading_overlay" class="scroll_y" >
      <v-card-text >
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <slot name="fields" :editMode="editMode" :selects="selects" ></slot>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import formMixin from "@/mixins/FormMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjSoftTitle from "@/components/MjSoftTitle";

export default {
  name: "MjEntityForm",
  mixins: [formMixin],
  components: {MjSchedaToolbar, MjSoftTitle},
  props: {
    "title": {default: () => ''},
    "form":{default: () => {}},
    "apiUrl":{default: () => ''}
  },
};
</script>
