<template>
  <v-container>
    <v-container>
      <mj-soft-title class="mb-3" icon="mdi-alpha-a-circle-outline" title="Codici Anthea"></mj-soft-title>
    </v-container>
    <v-form ref="form">
      <v-simple-table class="mj_simple_table mj_table_border_left mj_table_border_right">
        <thead>
        <tr>
          <th class="text-right">Codice</th>
          <th>Prodotto</th>
          <th>Produttore</th>
          <th>Intermediario</th>
          <th>Destino</th>
        </tr>
        </thead>
        <tbody>
        <tr v-show="loadingAntheaCodes > 0 ">
          <td colspan="20" class="loader_td">
            <v-progress-linear indeterminate></v-progress-linear>
          </td>
        </tr>
        <tr>
          <td class="white ">
            <v-text-field v-model="code" :rules="[rules.required]"
                          solo dense flat hide-details full-width reverse ></v-text-field>
          </td>
          <td class="pa-0   white ">
            <v-autocomplete :loading="loadingProductTypes > 0" v-model="selectedProductTypeId" :rules="[rules.required]"
                            placeholder="..." solo dense flat hide-details full-width
                            item-text="cod" item-value="id" :items="productTypes" class="transparent">
            </v-autocomplete>
          </td>
          <td class="pa-0 white ">
            <v-autocomplete :loading="loadingProductionPlants > 0" v-model="selectedProductionPlantId"
                            :rules="[rules.required]"
                            placeholder="..." solo dense flat hide-details full-width
                            item-text="cod" item-value="id" :items="productionPlants">
            </v-autocomplete>
          </td>
          <td class="pa-0 white ">
            <v-autocomplete :loading="loadingIntermediaries>0" v-model="selectedIntermediaryId"
                            placeholder="..." solo dense flat hide-details full-width
                            item-text="cod" item-value="id" :items="intermediaries">
            </v-autocomplete>
          </td>

          <td class="pa-0 white ">
            <v-autocomplete :loading="loadingDestinationPlants>0" v-model="selectedDestinationPlantId"
                            :rules="[rules.required]"
                            placeholder="..." solo dense flat hide-details full-width
                            item-text="cod" item-value="id" :items="destinationPlants">

            </v-autocomplete>
          </td>

          <td>
            <v-btn color="primary" icon @click="()=>{addAntheaCode().then(this.loadAntheaCodes)}" :loading="addingAntheaCode > 0">
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </td>

        </tr>

        <tr v-for="antheaCode in antheaCodes" :key="antheaCode.id">
          <td class="text-right ">
            
            <v-text-field v-model="antheaCode.code"
                          solo dense flat hide-details full-width reverse
                          append-outer-icon="mdi-content-save" background-color="transparent"
                          @click:append-outer="updateAntheaCode(antheaCode)" :loading="updatingCode[antheaCode.id] > 0 "
            >
            </v-text-field>
          </td>
          <td :style="{color:(antheaCode.productType) ? '#'+antheaCode.productType.color_hex : '' }">
            {{ antheaCode.productType ? antheaCode.productType.cod : '-' }}
          </td>
          <td>{{ antheaCode.productionPlant ? antheaCode.productionPlant.cod : '-' }}</td>
          <td>{{ antheaCode.intermediary ? antheaCode.intermediary.cod : '-' }}</td>
          <td>{{ antheaCode.destinationPlant ? antheaCode.destinationPlant.cod : '-' }}</td>


          <td>
            <v-btn color="red" icon @click="()=>{removeAntheaCode(antheaCode.id)}" :loading="removingAntheaCode[antheaCode.id]>0">
              <v-icon>mdi-minus-circle-outline</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </v-container>
</template>

<script>
import MjSoftTitle from "@/components/MjSoftTitle";
import mj_axios from "@/mj_axios";
import mj_string_utils from "@/misc/mj_string_utils";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import ReactivityMixin from "@/mixins/ReactivityMixin";
import ProductTypeMixin from "@/mixins/entities/ProductTypeMixin";
import ProductionPlantMixin from "@/mixins/entities/ProductionPlantMixin";
import IntermediaryMixin from "@/mixins/entities/IntermediaryMixin";
import DestinationPlantMixin from "@/mixins/entities/DestinationPlantMixin";

export default {
  name: "ShipmentTariffsView",
  components: {MjSoftTitle},
  mixins: [ValidationRulesMixin, ReactivityMixin, ProductTypeMixin,ProductionPlantMixin,IntermediaryMixin,DestinationPlantMixin],
  created() {
    this.loadProductTypes();
    this.loadProductionPlants();
    this.loadIntermediaries();
    this.loadDestinationPlants();
    this.loadAntheaCodes();
  },
  data: function () {
    return {
      mj_string_utils: mj_string_utils,


      antheaCodes: [],
      loadingAntheaCodes: 0,
      addingAntheaCode: 0,
      updatingCode: [],
      removingAntheaCode: [],

      selectedProductTypeId: null,
      selectedProductionPlantId: null,
      selectedIntermediaryId: null,
      selectedVehicleTypeId: null,
      selectedDestinationPlantId: null,
      code: 0,
    }
  },
  methods: {


    //CRUD TARIFFS
    loadAntheaCodes() {
      this.loadingAntheaCodes++;
      const axios_promise = mj_axios.get('/api/anthea_codes');
      axios_promise.then(response => {
        this.resetArray("anthea_codes");
        // this.updateArray("anthea_codes", response.data.data)
        this.antheaCodes = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingAntheaCodes--;
      });
      return axios_promise;
    },

    addAntheaCode() {

      if (this.$refs.form.validate()) {

        this.addingAntheaCode++;
        const params = {
          "product_type_id": this.selectedProductTypeId,
          "production_plant_id": this.selectedProductionPlantId,
          "intermediary_id": this.selectedIntermediaryId,
          "destination_plant_id": this.selectedDestinationPlantId,
          "code": this.code,
        };
        const axios_promise = mj_axios.post('/api/anthea_codes', params);
        axios_promise.then(() => {
        }).catch((e) => {
          const status = (e.response) ? e.response.status : null;
          let validationMessage = 'Assicurati che la combinazione assegnata non sia già in uso.';
          if (status == 422) {
            validationMessage = e.response.data.message;
          }
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: `Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.\n\n${validationMessage}`,
            visible: true,
          });
        }).then(() => {
          this.addingAntheaCode--;
        });
        return axios_promise;
      } else {
        const fieldHasError = this.$refs.form.inputs.find(field => field.hasError);
        fieldHasError.focus();
        return Promise.reject();
      }
    },
    updateAntheaCode(antheaCode) {

      this.$set(this.updatingCode, antheaCode.id, 1);

      const params = {
        "product_type_id": antheaCode.productType.id,
        "production_plant_id": antheaCode.productionPlant.id,
        "intermediary_id": antheaCode.intermediary.id,
        "destination_plant_id": antheaCode.destinationPlant.id,
        "code": antheaCode.code,
      }
      const axios_promise = mj_axios.put(`/api/anthea_codes${antheaCode.id}`, params);
      axios_promise.then(() => {

      }).catch((e) => {
            const status = (e.response) ? e.response.status : null;
            let validationMessage = 'Assicurati che la combinazione assegnata non sia già in uso.';
            if (status == 422) {
              validationMessage = e.response.data.message;
            }
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: `Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.\n\n${validationMessage}`,
          visible: true,
        });
      }).then(() => {
        this.$set(this.updatingCode, antheaCode.id, 0);
      });
      return axios_promise;
    },
    removeAntheaCode(antheaCodeId) {
      this.$set(this.removingAntheaCode, antheaCodeId, 1);
      const antheaCodeIds = JSON.stringify([antheaCodeId]);
      const axios_promise = mj_axios.delete(`/api/anthea_codes${antheaCodeIds}`);
      axios_promise.then(() => {
        this.antheaCodes = this.antheaCodes.filter((antheaCode) => antheaCode.id != antheaCodeId);
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.$set(this.removingAntheaCode, antheaCodeId, 0);
      });
      return axios_promise;
    },
  }, watch: {
    

  }
}
</script>

<style scoped>

</style>