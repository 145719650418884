/*
SCOPO DEL COMPONENTE: Mostrare dei button in modalità toggle per la scelta tra piu valori.

INPUT: array con gli items formato come nell'esempio seguente oppure il loadItemUrl per il caricamento degli stessi tramite ajax

:items_prop="[{description:'prova', value:1, icon:'mdi-close'},{description:'prov2', value:2}]"

l'icona è opzionale.

la prop description_as_tooltip indica se mostrare le descrizione dentro i tooltip e toglierle dai button per avere una visione più compatta

OUTPUT: array dei valori selezionati, modalità (and / or), glue (and or tra filtri - diversi mjcombobox)
*/

<template>
  <v-container class="px-0 " style="width: auto">
    <v-progress-linear indeterminate v-show="loading" style="width: 300px"></v-progress-linear>
    <div class="mj_label mb-2" v-if="label">{{ label }}</div>
    <v-btn-toggle
        v-model="selected"
        :multiple="multiple"
        @change="changeHandler"
        :active-class="active_class"
        :mandatory="mandatory"
    >
      <v-btn v-for="item in items" :key="item.id" :small="small" :value="item[item_value_field]" :elevation="elevation">

        <v-tooltip top v-if="description_as_tooltip !== false">
          <template v-slot:activator="{on,attrs}">
            <v-icon :color="item.text_color" v-bind="attrs"
                    v-on="on">{{ item.icon }}
            </v-icon>
          </template>
          {{ getDescription(item) }}
        </v-tooltip>
        <span v-else>
          <v-icon :color="item.text_color">{{ item.icon }}</v-icon>
          {{ getDescription(item) }}
        </span>
      </v-btn>
    </v-btn-toggle>
  </v-container>
</template>
<script>

import mj_axios from "@/mj_axios";

export default {
  name: "MjToggleButtons",
  props: {

    label: {},
    multiple: {default: () => false},
    load_items_url: {},
    items_prop: {type: Array, default: () => []},
    item_description_field: {},
    item_value_field: {},
    small: {},
    // return_object: {default:false},
    description_as_tooltip: {default: () => false},
    elevation: {default: () => 0},
    value: {},
    active_class: {default: () => ''},
    mandatory: {default: () => false}
  },
  computed: {},
  created() {
    this.loadItems().then(() => {
    });
  },
  data: function () {
    return {
      selected: this.value,
      items: this.items_prop,
      loading: false,
    };
  },
  methods: {

    changeHandler() {
      this.$emit('input', this.selected);
    },
    getDescription: function (item) {
      return (typeof this.item_description_field === "function") ? this.item_description_field(item) : item[this.item_description_field];
    },
    loadItems() {
      return new Promise((resolve, reject) => {
        if (this.load_items_url) {
          this.loading = true;
          mj_axios
              .get(this.load_items_url)
              .then((response) => {
                this.items = response.data;

                //assegno gli item tramite $set altrimenti non funziona il sistema di reattività vue.
                // for (let i = 0; i < items.length; i++)
                // {this.$set(this.items,i,items[i]);}
                resolve();

              })
              .catch(() => {

                this.error_text = "Errore nel caricamento dati";
                reject();
              })
              .then(() => {
                this.loading = false;
              });
        } else {
          resolve();
        }
      });
    },


  }
  ,
  watch: {
    value:
        {
          immediate: true,
          handler() {
            this.selected = this.value;
          }
        }
    ,
  }
  ,
}
;
</script>