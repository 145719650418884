<template>
  <v-container fluid>
    <v-row align="baseline" justify="space-between">
      <v-col sm="3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                elevation="6"
                v-on="on"
                v-bind="attrs"
                color="primary " x-small dense
                :loading="approvingShipmentPlanning>0"
                @click="showShipmentPlanningApproveDialog = true"
                fab
            >
              <v-icon>mdi-stamper</v-icon>
            </v-btn>
          </template>
          <span>Approva Pianificazione</span>

        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="ml-2"
                elevation="6"
                v-on="on"
                v-bind="attrs"
                x-small dense
                :loading="sendingWeeklyReportMail>0"
                @click="showSendingMailDialog = true; sendingMailTestMode = true"
                fab
            >
              <v-icon color="grey">mdi-email</v-icon>
            </v-btn>
          </template>
          <span>TEST: Invia Pianificazione</span>

        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="ml-2"
                elevation="6"
                v-on="on"
                v-bind="attrs"
                color="secondary " x-small dense
                :loading="sendingWeeklyReportMail>0"
                @click="showSendingMailDialog = true"
                fab
            >
              <v-icon>mdi-email</v-icon>
            </v-btn>
          </template>
          <span>Invia Pianificazione</span>

        </v-tooltip>
      </v-col>


      <v-col sm="6">
        <v-row justify-sm="end">
          <v-col cols="2">
            <ShipmentCounter status="DRAFT" :week-id="this.weekId" ref="draftShipmentCounter"></ShipmentCounter>
          </v-col>
          <v-col cols="2">
            <ShipmentCounter status="PLANNED" :week-id="this.weekId" ref="plannedShipmentCounter"></ShipmentCounter>
          </v-col>
          <v-col cols="2">
            <ShipmentCounter status="CANCELED" :week-id="this.weekId" ref="canceledShipmentCounter"></ShipmentCounter>
          </v-col>
          <v-col cols="2">
            <ShipmentCounter status="SUSPENDED" :week-id="this.weekId" ref="suspendedShipmentCounter"></ShipmentCounter>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
    <MjConfirmDialog v-model="showShipmentPlanningApproveDialog"
                     dialog_title="Attenzione"
                     dialog_text="Approvando la pianificazione tutti viaggi in stato di BOZZA saranno aggiornati con lo stato PIANIFICATO e potranno essere inviati al trasportatore, il valore di revisione del viaggio sarà incrementato."
                     @confirm-from-dialog="approveShipmentPlanning"
                     @cancel-from-dialog="showShipmentPlanningApproveDialog = false"
    ></MjConfirmDialog>

    <MjConfirmDialog v-model="showSendingMailDialog"
                     dialog_title="Attenzione"
                     :dialog_text="`Il sistema invierà una mail con la pianificazione per la settimana ${this.weekId} a tutti i trasportatori che hanno dei viaggi associati`"
                     @confirm-from-dialog="mailShipmentNextWeekPlanning"
                     @cancel-from-dialog="showSendingMailDialog = false"
    ></MjConfirmDialog>
    <v-snackbar app
                v-model="snackBarShow"
                timeout="15000"
                type="info"
                width="100%"
                color="info"
                vertical="vertical">{{ snackBarText }}
    </v-snackbar>

  </v-container>
</template>

<script>
import mj_axios from "@/mj_axios";
import MjConfirmDialog from "@/components/MjConfirmDialog";
import ShipmentCounter from "@/components/custom/ShipmentCounter";
import mj_date_utils from "@/misc/mj_date_utils";


export default {
  name: "ShipmentPlanningApprover",
  components: {MjConfirmDialog, ShipmentCounter},
  mixins: [],
  props: {weekId: {}},
  created() {

  },
  data: function () {
    return {
      approvingShipmentPlanning: 0,
      showShipmentPlanningApproveDialog: false,
      sendingWeeklyReportMail: 0,
      sendingMailTestMode: false,
      showSendingMailDialog: false,
      snackBarShow: false,
      snackBarText: '',
    }
  },
  methods: {

    approveShipmentPlanning() {
      this.showShipmentPlanningApproveDialog = false;
      this.approvingShipmentPlanning++;
      const params = {weekId: this.weekId};
      const axios_promise = mj_axios.post('/api/shipments/actions/approve', params);
      axios_promise.then(() => {
        this.updateCounters();

      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di salvataggio approvazione pianificazione, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.approvingShipmentPlanning--;

      });
      return axios_promise;
    },
    mailShipmentNextWeekPlanning() {

      const nextWeekId = mj_date_utils.getNextWeekId();

      this.sendingWeeklyReportMail++;

      const params = {weekId: nextWeekId, testMode:this.sendingMailTestMode};
      const axios_promise = mj_axios.post(`/api/carriers/actions/mail_weekly_report`, params);
      this.sendingMailTestMode = false;
      axios_promise.then(() => {
        this.snackBarShow = true;
        this.snackBarText = "L'invio delle mail è stato schedulato, a breve i destinatari riceveranno le mail.\n Il sistema invia email solo ai trasportatori che hanno viaggi assegnati nella pianificazione il cui stato è PIANIFICATO."
        this.showSendingMailDialog = false;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di invio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.sendingWeeklyReportMail--;

      });
      return axios_promise;

    },
    updateCounters() {
      this.$refs.draftShipmentCounter.loadShipmentCount();
      this.$refs.plannedShipmentCounter.loadShipmentCount();
      this.$refs.canceledShipmentCounter.loadShipmentCount();
      this.$refs.suspendedShipmentCounter.loadShipmentCount();
    }
  }
}
</script>

<style scoped>

</style>