<template>
  <v-container fill-height>
   <MjMessageCard :show=show_box :title=box_title :sub_title=box_sub_title :action_text=box_action_text :action_icon=box_action_icon :action_link=box_action_link ></MjMessageCard>
  </v-container>
</template>

<script>
import MjMessageCard from "@/components/MjMessageCard.vue";
import mj_axios from '@/mj_axios.js'
export default {
  components:{MjMessageCard},
  data() {return {
    show_box: true,
    box_title:'',
    box_sub_title:'',
    box_action_text: '',
    box_action_link: '',
    box_action_icon: '',
    token: this.$route.query.token || "",
  }
  },
  created(){mj_axios
          .get("/api/user/activate/"+this.token)
          .then(() => {
            this.show_box = true;
            this.box_title = 'Ben Fatto';
            this.box_sub_title = 'Il tuo account adesso è attivo';
            this.box_action_text =  'Vai al Login';
            this.box_action_link = '/utente/login';
            this.box_action_icon = 'mdi-arrow-right';
          })
          .catch(e => {
            
            this.show_box = true;
            this.box_title = 'Attenzione';
            this.box_sub_title = "Errore Durante la Registrazione: " + e.response.data.message;
            this.box_action_text =  'Vai alla Home';
            this.box_action_link = '/';
            this.box_action_icon = 'mdi-home';
          })
          .then(() => {
            // always executed
            this.registration_btn_loading = false;
          });}

}
</script>
