<template>
  <v-container>
    <MjEntityForm :title="'Tipo Prodotto ' + (($route.params.id) ? $route.params.id : 'Nuovo') " :form="form"
                  :apiUrl="apiUrl"
                  @loadedItem="loadedItem"
    >
      <!--eslint-disable-next-line vue/no-unused-vars-->
      <template #fields="slotProps">
        <v-row>
          <v-col cols='12' sm='3'>
            <v-text-field
                label='Alias'
                v-model='form.cod.val'
                :rules="[rules.required,rules.max(15)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' sm='8'>
            <v-text-field
                label='Name'
                v-model='form.name.val'
                :rules="[rules.max(64)]">
            </v-text-field>
          </v-col>

        </v-row>
        <v-row justify="space-between">
          <v-col cols="12" sm="2">
            <v-text-field type="number"
                          label="Giacenza"
                          v-model="form.stock_quantity.val"
                          :rules="[rules.float()]">

            </v-text-field>
          </v-col>
          <v-col cols='12' sm='6' >
            <v-color-picker

                @input="(color) => form.color_hex.val = color.replace('#','').substring(0,6)"
                :value="productColorHex"
                hide-inputs

                canvas-height="40"
            >
            </v-color-picker>

          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Note'
                v-model='form.note.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
        </v-row>

      </template>
    </MjEntityForm>
  </v-container>
</template>

<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjEntityForm from "@/components/form/MjEntityForm";


export default {
  name: "ProductTypeFormView",
  mixins: [ValidationRulesMixin],
  components: {MjEntityForm},
  data: () => ({
    productColorHex: '#FFFFFF',
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      name: {type: 'string', val: '', default_val: ''},
      stock_quantity: {type: 'float', val: '', default_val: ''},
      color_hex: {type: 'string', val: '', default_val: ''},
      note: {type: 'text', val: '', default_val: ''},
    },
    apiUrl: "/api/product_types"
  }),
  methods: {
    loadedItem: function () {
      this.productColorHex = '#' + this.form.color_hex.val;
    }
  }
};
</script>
