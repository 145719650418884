<template>
  <v-container fluid>
    <WeekNavigator @change="changeWeek" class="mb-4"/>
    <v-row align="baseline">
      <v-col>
        <mj-soft-title class="mb-5 ml-3" icon="mdi-calendar-end" :title="`Pianificazione Viaggi`"></mj-soft-title>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1">
      <v-btn icon @click="refreshTab"><v-icon>mdi-refresh</v-icon></v-btn>
      </v-col>
    </v-row>

    <v-toolbar class="gradient_background mb-2" elevation="1" flat  dense>
    <ShipmentPlanningApprover :week-id="this.selectedWeekId" class="px-0" ref="approver"></ShipmentPlanningApprover>
    </v-toolbar>

    <v-progress-linear indeterminate v-show="loadingProductionPlants"></v-progress-linear>

    <v-tabs v-model="selectedProductionPlantTab" show-arrows background-color="primary" class="rounded-t">
      <v-tab v-for="productionPlant in productionPlants" :key="productionPlant.id" class="text_on_primary_background" >
        {{ productionPlant.cod }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedProductionPlantTab" class="rounded-b">
      <v-sheet style="border:solid 1px #ccc">
        <v-tab-item v-for="productionPlant in productionPlants" :key="productionPlant.id" class="grey pa-2 lighten-3">
          <ShipmentRequestVsPlanning :productionPlant="productionPlant" :week-id="selectedWeekId" :ref="'shipmentRequestVsPlanning_'+productionPlant.id"></ShipmentRequestVsPlanning>
        </v-tab-item>
      </v-sheet>
    </v-tabs-items>

  </v-container>
</template>

<script>
import MjSoftTitle from "@/components/MjSoftTitle";
import ShipmentRequestVsPlanning from "@/components/custom/ShipmentRequestVsPlanning";
import ProductionPlantMixin from "@/mixins/entities/ProductionPlantMixin";
import WeekNavigator from "@/components/custom/WeekNavigator";

import ShipmentPlanningApprover from "@/components/custom/ShipmentPlanningApprover";

export default {
  name: "PlanningShipments",
  components: {
    ShipmentPlanningApprover,
     WeekNavigator, ShipmentRequestVsPlanning, MjSoftTitle},
  mixins: [ProductionPlantMixin],
  created() {
    this.loadProductionPlants();
  },

  data: function () {
    return {
      selectedProductionPlantTab: 0,
      selectedWeekId: null,
    }
  },
  methods: {
    changeWeek(weekId) {
      this.selectedWeekId = weekId;
    },
    refreshTab(){
      const productionPlantId = this.productionPlants[this.selectedProductionPlantTab]['id'];
      this.$refs[`shipmentRequestVsPlanning_${productionPlantId}`][0].loadTableData()
      this.$refs.approver.updateCounters();
    },

  }
}
</script>

<style scoped>

</style>