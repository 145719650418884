<template>
  <v-container>
    <MjEntityForm :title="'Trasportatore ' + (($route.params.id) ? $route.params.id : 'Nuovo') " :form="form"
                  :apiUrl="apiUrl">
      <!--eslint-disable-next-line vue/no-unused-vars-->
      <template #fields="slotProps">
        <v-row>
          <v-col cols='12' md='2'>
            <v-text-field
                label='Alias'
                v-model='form.cod.val'
                :rules="[rules.required,rules.max(6)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='5'>
            <v-text-field
                label='Nome'
                v-model='form.name.val'
                :rules="[rules.required,rules.max(64)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='5'>
            <v-text-field
                label='Email'
                v-model='form.email.val'
                :rules="[rules.required,rules.max(64)]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Tel'
                v-model='form.tel.val'
                :rules="[rules.max(64)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='5'>
            <v-text-field
                label='Persona Riferimento'
                v-model='form.reference_person_info.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='2'>
            <v-text-field
                label='Transport Category'
                v-model='form.transport_category.val'
                :rules="[rules.integer]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
                prepend-icon="mdi-package-variant"
                label='Tipo prodotti'
                chips
                deletable-chips
                multiple
                v-model='form.productTypesIds.val'
                item-text="cod"
                item-value="id"
                :items="slotProps.selects.productTypesIds"
                cache-items
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              prepend-icon="mdi-truck-trailer"
              label='Veicoli'
              chips
              deletable-chips
              multiple
              v-model='form.vehicleTypesIds.val'
              :item-text="(item) => item.cod + ' ' + item.name"
              item-value="id"
              :items="slotProps.selects.vehicleTypesIds"
              cache-items
          >
          </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Nota'
                v-model='form.note.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
        </v-row>

      </template>
    </MjEntityForm>
  </v-container>
</template>

<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjEntityForm from "@/components/form/MjEntityForm";


export default {
  name: "CarrierFormView",
  mixins: [ValidationRulesMixin],
  components: {MjEntityForm,},
  data: () => ({
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      name: {type: 'string', val: '', default_val: ''},
      email: {type: 'string', val: '', default_val: ''},
      tel: {type: 'string', val: '', default_val: ''},
      reference_person_info: {type: 'string', val: '', default_val: ''},
      transport_category: {type: 'int', val: '', default_val: ''},

      note: {type: 'text', val: '', default_val: ''},
      vehicleTypesIds: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/vehicle_types'
      },
      productTypesIds: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/product_types'
      },
    },
    apiUrl: "/api/carriers"
  }),
};
</script>
