import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import mj_config from "../mj_config";

Vue.use(Vuetify);
//Trustworthy Teal Tones via N26:#2b6777 #c8d8e4 #ffffff #f2f2f2 #52ab98
export default new Vuetify({
    theme: {
        dark: mj_config.dark_mode_on(),
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#2b6777',
                textonprimarybackground:'#eeeeee',
                secondary: '#ffbb00',
                danger: "#EF5350",
                backgroundcolor: "#e3e3e3", // Not automatically applied
                backgroundgradientcolor1: "#ffffff", // Not automatically applied
                backgroundgradientcolor2: "#e3e3e3", // Not automatically applied

            },
            dark: {
                primary: '#ffbb00',
                textonprimarybackground:'#1111111',
                secondary: '#003974',
                warning: '#473571',
                error: '#00D1EC',
                danger: '#00D1EC',
                info: '#326abf',
                background: "#3f3f3f" // Not automatically applied
            }
        }
    }
});
