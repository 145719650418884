<template>
  <v-container>
    <v-container>
      <mj-soft-title class="mb-3" icon="mdi-euro-sign" title="Tariffario"></mj-soft-title>
    </v-container>
    <v-form ref="form">
      <v-simple-table class="mj_simple_table mj_table_border_left mj_table_border_right">
        <thead>
        <tr>
          <th>Prodotto</th>
          <th>Imballo</th>
          <th>Trasportatore</th>
          <th>Veicolo</th>
          <th>Destino</th>
          <th class="text-right">Prezzo</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-show="loadingTariffs > 0 ">
          <td colspan="20" class="loader_td">
            <v-progress-linear indeterminate></v-progress-linear>
          </td>
        </tr>
        <tr>
          <td class="pa-0   white ">
            <v-autocomplete :loading="loadingProductTypes > 0" v-model="selectedProductTypeId" :rules="[rules.required]"
                            placeholder="..." solo dense flat hide-details full-width
                            item-text="cod" item-value="id" :items="productTypes" class="transparent">
            </v-autocomplete>
          </td>
          <td class="pa-0 white ">
            <v-autocomplete :loading="loadingPackagingTypes > 0" v-model="selectedPackagingTypeId"
                            :rules="[rules.required]"
                            placeholder="..." solo dense flat hide-details full-width
                            item-text="cod" item-value="id" :items="packagingTypes">
            </v-autocomplete>
          </td>
          <td class="pa-0 white ">
            <v-autocomplete :loading="loadingCarriers>0" v-model="selectedCarrierId" :rules="[rules.required]"
                            placeholder="..." solo dense flat hide-details full-width
                            item-text="cod" item-value="id" :items="carriers">
            </v-autocomplete>
          </td>
          <td class="pa-0 white ">
            <v-autocomplete :loading="loadingVehicleTypes>0" v-model="selectedVehicleTypeId" :rules="[rules.required]"
                            placeholder="..." solo dense flat hide-details full-width
                            item-text="cod" item-value="id" :items="vehicleTypes">

            </v-autocomplete>
          </td>
          <td class="pa-0 white ">
            <v-autocomplete :loading="loadingDestinationPlants>0" v-model="selectedDestinationPlantId"
                            :rules="[rules.required]"
                            placeholder="..." solo dense flat hide-details full-width
                            item-text="cod" item-value="id" :items="destinationPlants">

            </v-autocomplete>
          </td>
          <td class="white ">
            <v-text-field v-model="price" :rules="[rules.required, rules.positive]"
                          solo dense flat hide-details full-width reverse append-icon="mdi-currency-eur">
            </v-text-field>
          </td>
          <td>
            <v-btn color="primary" icon @click="()=>{addTariff().then(this.loadTariffs)}" :loading="addingTariff > 0">
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </td>

        </tr>

        <tr v-for="tariff in tariffs" :key="tariff.id">
          <td :style="{color:(tariff.productType) ? '#'+tariff.productType.color_hex : '' }">
            {{ tariff.productType ? tariff.productType.cod : '-' }}
          </td>
          <td>{{ tariff.packagingType ? tariff.packagingType.cod : '-' }}</td>
          <td>{{ tariff.carrier ? tariff.carrier.cod : '-' }}</td>
          <td>{{ tariff.vehicleType ? tariff.vehicleType.cod : '-' }}</td>
          <td>{{ tariff.destinationPlant ? tariff.destinationPlant.cod : '-' }}</td>
          <td class="text-right ">
            <!-- {{mj_string_utils.moneyFormat(tariff.price, 'it') }} -->
            <v-text-field v-model="tariff.price"
                          solo dense flat hide-details full-width reverse append-icon="mdi-currency-eur"
                          append-outer-icon="mdi-content-save" background-color="transparent"
                          @click:append-outer="updateTariffPrice(tariff)" :loading="updatingTariff[tariff.id] > 0 "
            >
            </v-text-field>
          </td>

          <td>
            <v-btn color="red" icon @click="()=>{removeTariff(tariff.id)}" :loading="removingTariff[tariff.id]>0">
              <v-icon>mdi-minus-circle-outline</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </v-container>
</template>

<script>
import MjSoftTitle from "@/components/MjSoftTitle";
import mj_axios from "@/mj_axios";
import mj_string_utils from "@/misc/mj_string_utils";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import ReactivityMixin from "@/mixins/ReactivityMixin";
import mj_number_utils from "@/misc/mj_number_utils";

export default {
  name: "ShipmentTariffsView",
  components: {MjSoftTitle},
  mixins: [ValidationRulesMixin, ReactivityMixin],
  created() {
    this.loadProductTypes();
    this.loadPackagingTypes();
    this.loadCarriers();
    this.loadVehicleTypes();
    this.loadDestinationPlants();

    this.loadTariffs();
  },
  data: function () {
    return {
      mj_string_utils: mj_string_utils,
      productTypes: [],
      loadingProductTypes: 0,
      packagingTypes: [],
      loadingPackagingTypes: 0,
      carriers: [],
      loadingCarriers: 0,
      vehicleTypes: [],
      loadingVehicleTypes: 0,
      destinationPlants: [],
      loadingDestinationPlants: 0,

      tariffs: [],
      loadingTariffs: 0,
      addingTariff: 0,
      updatingTariff: [],
      removingTariff: [],

      selectedProductTypeId: null,
      selectedPackagingTypeId: null,
      selectedCarrierId: null,
      selectedVehicleTypeId: null,
      selectedDestinationPlantId: null,
      price: 0,
    }
  },
  methods: {
    //AUTOCOMPLETES
    loadProductTypes() {
      this.loadingProductTypes++;
      const axios_promise = mj_axios.get('/api/product_types');
      axios_promise.then(response => {
        this.productTypes = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingProductTypes--;
      });
      return axios_promise;
    },
    loadPackagingTypes() {
      this.loadingPackagingTypes++;
      const axios_promise = mj_axios.get('/api/packaging_types');
      axios_promise.then(response => {
        this.packagingTypes = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingPackagingTypes--;
      });
      return axios_promise;
    },
    loadCarriers() {
      this.loadingCarriers++;
      const axios_promise = mj_axios.get('/api/carriers');
      axios_promise.then(response => {
        this.carriers = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingCarriers--;
      });
      return axios_promise;
    },
    loadVehicleTypes() {
      this.loadingVehicleTypes++;
      const axios_promise = mj_axios.get('/api/vehicle_types');
      axios_promise.then(response => {
        this.vehicleTypes = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingVehicleTypes--;
      });
      return axios_promise;
    },
    loadDestinationPlants() {
      this.loadingDestinationPlants++;
      const axios_promise = mj_axios.get('/api/destination_plants');
      axios_promise.then(response => {
        this.destinationPlants = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingDestinationPlants--;
      });
      return axios_promise;
    },

    //CRUD TARIFFS
    loadTariffs() {
      this.loadingTariffs++;
      const axios_promise = mj_axios.get('/api/tariffs');
      axios_promise.then(response => {
        this.resetArray("tariffs");
        // this.updateArray("tariffs", response.data.data)
        this.tariffs = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingTariffs--;
      });
      return axios_promise;
    },

    addTariff() {

      if (this.$refs.form.validate()) {

        this.addingTariff++;
        const params = {
          "product_type_id": this.selectedProductTypeId,
          "packaging_type_id": this.selectedPackagingTypeId,
          "carrier_id": this.selectedCarrierId,
          "vehicle_type_id": this.selectedVehicleTypeId,
          "destination_plant_id": this.selectedDestinationPlantId,
          "price": mj_number_utils.parseFloatITFormatted(this.price),
        };
        const axios_promise = mj_axios.post('/api/tariffs', params);
        axios_promise.then(() => {
        }).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.",
            visible: true,
          });
        }).then(() => {
          this.addingTariff--;
        });
        return axios_promise;
      } else {
        const fieldHasError = this.$refs.form.inputs.find(field => field.hasError);
        fieldHasError.focus();
        return Promise.reject();
      }
    },
    updateTariffPrice(tariff) {

      this.$set(this.updatingTariff, tariff.id, 1);

      const params = {
        "product_type_id": tariff.productType.id,
        "packaging_type_id": tariff.packagingType.id,
        "carrier_id": tariff.carrier.id,
        "vehicle_type_id": tariff.vehicleType.id,
        "destination_plant_id": tariff.destinationPlant.id,
        "price": mj_number_utils.parseFloatITFormatted(tariff.price),
      }
      const axios_promise = mj_axios.put(`/api/tariffs/${tariff.id}`, params);
      axios_promise.then(() => {

      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.$set(this.updatingTariff, tariff.id, 0);
      });
      return axios_promise;
    },
    removeTariff(tariffId) {
      this.$set(this.removingTariff, tariffId, 1);
      const tariffIds = JSON.stringify([tariffId]);
      const axios_promise = mj_axios.delete(`/api/tariffs/${tariffIds}`);
      axios_promise.then(() => {
        this.tariffs = this.tariffs.filter((tariff) => tariff.id != tariffId);
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.$set(this.removingTariff, tariffId, 0);
      });
      return axios_promise;
    },
  }, watch: {
    

  }
}
</script>

<style scoped>

</style>