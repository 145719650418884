/*
* MjImage - serve a gestire la visualizzazione e upload di una singola immagine (es. avatar profilo o immagine singola da associare ad una entità)
*
* Funziona in due modalità a secondo della prop 'shape' che può essere 'circle' o 'rectangle' (in realtà è rectangle qualsiasi cosa non sia circle)
*
* Le dimensioni in modalità circle sono determinate dalla prop circle_size, in modalità rect da width e height.
*
* CIRCLE MODE:
* - Sfrutta avatar e un badge (non si è usato v-badge in quanto aveva dei limiti sulle dimensioni)
* - L'intera immagine + badge è cliccabile per l'upload
* - Se manca l'immagine viene mostrato il testo (prop text) trasformato in uppercase
*
* RECTANGLE MODE:
* - Sfrutta una v-card con v-img e le action (nelle quali ci sono i v-btn)
* - Solo il v-btn camera è cliccabile per aprire il filechooser
* - Solo in questa modalità esiste il v-btn per resettare l'immagine
* - Se non c'è immagine mostra un immagine di default (presa dagli assets mediante require)
*
* EMIT:
*  @img-changed -> L'immagine caricata viene comunicata tramite emit in formato base64
*
*/
<template>
  <v-card style="background-color: transparent" flat>
    <v-card
        @drop.prevent="addDroppedFiles"
        @dragenter.prevent="dragEnter"
        @dragleave.prevent="dragLeave"
        @dragover.prevent
        v-bind:class="[(dropEntered) ? 'drop_enter' : '', 'dropzone']"
        :style="image_container_style"
        :flat="shape === 'circle'"
    >
      <!--CIRCLE MODE-->
      <div v-if="(shape === 'circle')" :style="image_circle_style" @click.prevent.stop="pickImage">
        <v-avatar :size="circle_size" class="primary " style="cursor:pointer" >
          <v-img v-if="imageBase64Data" :src="imageBase64Data" alt="Profile Img"></v-img>
          <span v-else class="headline">{{ upperCaseText }}</span>
        </v-avatar>
        <v-btn fab elevation="1" :style="image_circle_button_style" bordered color="secondary">
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </div>
      <!--RECTANGLE MODE-->
      <v-card elevation="1"  v-else :style="image_rect_style">
        <v-img class="align-end" contain lazy-src="@/assets/images/no_image.png" :src="imageSrcComputed"  alt="Profile Img" :max-height="height" :max-width="width" :min-height="height" :min-width="width"></v-img>
        <v-card-actions >
          <v-spacer></v-spacer>
        <v-btn icon color="secondary" @click.prevent.stop="pickImage"><v-icon>mdi-camera</v-icon></v-btn>
        <v-btn icon color="warning" @click.stop="resetImage"><v-icon>mdi-close-circle</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
    <input :accept="mimes" class="img_input" ref="img_input" type="file" @change="imgInputChange"/>
    <v-snackbar v-model="error_show">
      {{ error_message }}
      <v-btn color="accent" text @click="error_show = false"
      >Chiudi
      </v-btn
      >
    </v-snackbar>
  </v-card>
</template>
<style>

/*rendere cursor pointer il componente per l'upload*/
.v-file-input__text {
  cursor: pointer !important;
}

.img_input {
  display: none;
}

.drop_enter {
  opacity: 0.2;
}

.badge {
  cursor: pointer
}

.badge:hover {
  opacity: 0.8
}
</style>

<script>
export default {
  name: "MjImage",
  props: {
    imageSrc: {default: ''},
    shape: {default: 'circle', type: String},
    text: {default: 'A'},
    circle_size: {default: 80},
    width: {default: 80}, //usato nel caso di circle per il diametro
    height: {default: 80},
    mimes: {
      type: String,
      default: 'image/png, image/gif, image/jpeg, image/bmp'
    },
  },

  computed: {
    image_container_style() {
      let style;
      if (this.shape === 'circle') {
        style = `background-color:transparent;height:${this.circle_size}px; width:${this.circle_size}px;`;
      } else {
        style = `min-height:${parseInt(this.height)+this.cardActionOffset}px; max-height:${this.height+this.cardActionOffset}px; max-width:${this.width}px;`;
      }
      return style;
    },
    image_circle_style() {
      return `height:${this.circle_size}px; width:${this.circle_size}px; position:relative;`;
    },
    image_rect_style() {
      return `max-height:${this.height}px; max-width:${this.width}px;border-radius:5px `;
    },
    image_circle_button_size() {
      return parseInt(this.circle_size / 3);
    },
    image_circle_button_style() {
      return `padding:5px; position: absolute; height:${this.image_circle_button_size}px; width:${this.image_circle_button_size}px; top:${this.circle_size - this.image_circle_button_size}px; left:${this.circle_size - this.image_circle_button_size}px `;
    },
    upperCaseText() {
      return this.text.toUpperCase();
    },
    imageSrcComputed(){
      const src =  (this.imageBase64Data && this.imageBase64Data !== '') ? this.imageBase64Data : require('@/assets/images/no_image.png');
      return src;
    }
  },
  data() {
    return {
      imageBase64Data: this.imageSrc,
      imageSrcData:'',
      dropEntered: false,
      dragCounter: 0, //conta i drag start vs drage leave per gestire l'opacità della dropzone
      cardActionOffset: 48,
      error_show: false,
      error_message: ''
    }
  },
  methods: {
    dragEnter(/*e*/) {
      if (this.dragCounter == 0) {
        this.dropEntered = true;
      }
      this.dragCounter++;

    },
    dragLeave(/*e*/) {
      this.dragCounter--;
      if (this.dragCounter == 0) {
        this.dropEntered = false;
      }
    },
    addDroppedFiles(e) {
      this.dropEntered = false;
      this.dragCounter = 0;
      if (e.dataTransfer.files) {
        this.imgInputChange(e);
      }
    },
    pickImage() {
      this.$refs.img_input.click();
    },
    imgInputChange(event) {
      let fileInput;
      if (event.type === 'change') {
        fileInput = this.$refs.img_input;
      } else if (event.type === 'drop') {
        fileInput = event.dataTransfer;
      }
      if (fileInput.files != null && fileInput.files[0] != null) {
        let correctType = this.mimes.split(', ').find(m => m === fileInput.files[0].type);
        if (!correctType) {

          this.error_show = true;
          this.error_message = "Tipo di file non supportato";
          return;
        }
        let reader = new FileReader()
        reader.onload = e => {
          this.imageBase64Data = e.target.result;
          this.$emit('img-changed', this.imageBase64Data);
        }
        reader.readAsDataURL(fileInput.files[0])
        this.filename = fileInput.files[0].name || 'unknown'
        this.mimeType = this.mimeType || fileInput.files[0].type

      }
    },
    resetImage:function (){
      this.imageBase64Data = null;
      this.$emit('img-changed', null);

    }
  },
  watch: {
    imageSrc: function (val) {
      this.imageBase64Data = val;
    }
  }

}
</script>

