import mj_axios from "@/mj_axios";

const destinationPlantMixin = {

    data: function () {
        return {
            destinationPlants: [],
            loadingDestinationPlants: 0,
        };
    },

    methods: {
        loadDestinationPlants() {
            this.loadingDestinationPlants++;
            const axios_promise = mj_axios.get('/api/destination_plants');
            axios_promise.then(response => {
                this.destinationPlants = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento tipi imballo, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingDestinationPlants--;
            });
            return axios_promise;
        },
    },

};

export default destinationPlantMixin;
