<template>
  <v-container fluid class="px-0">
    <v-row fixed>
      <!--vrow necessaria per la width 100%-->
      <v-snackbar
          app
          v-model="snack_bar_show"
          type="warning"
          width="100%"
          color="info"
          vertical="vertical"
      >{{ error_text }}
      </v-snackbar>
    </v-row>
    <v-toolbar class="gradient_background mb-2 " elevation="1" flat rounded dense >

      <v-tooltip top v-if="back_button_visible">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              @click="$emit('go-back-click')"
              v-shortkey="['meta', 'b']" @shortkey="$emit('go-back-click')"
          >
            <v-icon left>mdi-backburger</v-icon>
          </v-btn>
        </template>
        <span>Indietro</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              color="primary"
              :class="[edit_not_saved ? '' : 'text--lighten-3']"
              small
              @click="$emit('save-item-click')"
              v-shortkey="['meta', 's']"
              @shortkey="$emit('save-item-click')"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ this.edit_mode ? 'Aggiorna' : 'Salva' }}</span>
      </v-tooltip>
      <v-tooltip top v-if="restore_item_show">

        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-on="on"
              v-bind="attrs"
              color="green"
              icon
              @click="$emit('restore-item-click')"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
        <span>Ripristina</span>
      </v-tooltip>

      <v-tooltip top v-if="new_button_visibile">

        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              color="orange"
              @click="$emit('new-item-click')"
              v-shortkey="['meta', '+']" @shortkey="$emit('new-item-click')"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Nuovo</span>
      </v-tooltip>


    </v-toolbar>
    <v-speed-dial
        v-if="speedDialVisible"
        style="z-index: 777;margin-bottom: 41px; margin-right: 7px"
        absolute
        fixed
        v-model="speedDial"
        :bottom="true"
        :right="true"
        direction="top"
        open-on-hover
    >
      <template v-slot:activator>
        <v-btn
            v-model="speedDial"
            color="secondary"
            fab
            large
        >
          <v-icon v-if="speedDial">
            mdi-close
          </v-icon>
          <span v-else class="speed_dial_closed_text">
            {{ speedDialTitle }}
          </span>
        </v-btn>
      </template>
      <v-btn
          fab
          dark
          small
          color="primary"
          @click="$emit('save-item-click')"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
          fab
          dark
          small
          v-if="new_button_visibile"
          @click="$emit('new-item-click')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

    </v-speed-dial>
  </v-container>
</template>
<style>
.speed_dial_closed_text {
  font-size: smaller;
  max-width: 40px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<script>


export default {
  name: "SchedaToolbar",
  props: {
    edit_mode: {},
    new_button_visibile: {},
    back_button_visible: {default: () => true},
    restore_item_show: {},
    edit_not_saved: {},
    error_show: {},
    error_text: {},
    speedDialVisible: {},
    speedDialTitle: {}
  },
  data: function () {
    return {
      snack_bar_show: this.error_show,
      speedDial: false
    }
  },
  watch: {
    error_show(val) {
      this.snack_bar_show = val;
    }
  }
};
</script>