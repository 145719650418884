<!-- Riepilogo settimanale dei viaggi pianificati per il Prodotto vengono indicati i viaggi per ogni impianto di destinazione-->
<template>
  <v-simple-table class="mj_simple_table  mj_table_border_right ">
    <thead class="smaller text--secondary" v-if="hideHeader == undefined">
    <th class="destination_plant_th"></th>
    <th v-for="day in daysHeader" :key="day">{{ day }}</th>
    <th class="shipment_count_tot_th">TOT</th>

    </thead>
    <tbody>
    <tr v-if="loading > 0">
      <td colspan="20" class="loader_td">
        <v-progress-linear indeterminate></v-progress-linear>
      </td>
    </tr>
    <tr v-if="(shipmentsCountDayIndexed == null || shipmentsCountDayIndexed.length == 0) && loading == 0">
      <td></td>
      <td colspan="100">Nessun Viaggio Pianificato per il Prodotto</td>
    </tr>
    <tr v-else v-for="shipmentCountDayIndexed in shipmentsCountDayIndexed"
        :key="shipmentCountDayIndexed.destinationPlant.id"
        :class="[mj_array_utils.sumReduce(shipmentCountDayIndexed.plannedShipmentsDayIndexed) == 0 ? 'tr_half_opacity' : '']">
      <td>{{ shipmentCountDayIndexed.destinationPlant.name }}</td>
      <td v-for="(plannedShipmentDayIndexed,index) in shipmentCountDayIndexed.plannedShipmentsDayIndexed" :key="index"
          class="shipment_count_td">
        {{ plannedShipmentDayIndexed }}
      </td>
      <td class="shipment_count_tot_td">{{
          mj_array_utils.sumReduce(shipmentCountDayIndexed.plannedShipmentsDayIndexed)
        }}
      </td>
    </tr>

    </tbody>
  </v-simple-table>
</template>

<script>
import mj_axios from "@/mj_axios";
import mj_array_utils from "@/misc/mj_array_utils";

export default {
  name: "DestinationPlantShipmentsWeek",
  props: {
    hideHeader: {},
    productType: {type: Object},
    weekId: {default: () => null},
    hideEmptyDestinationPlant: {default: () => false},
    doRefresh: {default:()=>0},
  },
  created() {
    this.loadData()
  },
  data: function () {
    return {
      mj_array_utils: mj_array_utils,
      daysHeader: ['LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB'],
      shipmentsCountDayIndexed: [],
      loading: 0,

    }
  },
  methods: {
    loadData() {

      this.loading++;
      this.shipmentsCountDayIndexed = [];
      const params = {week_id: this.weekId, hide_empty_destination_plant: this.hideEmptyDestinationPlant};
      const axios_promise = mj_axios.get(`/api/product_types/${this.productType.id}/shipments_count_day_indexed`, {params: params});
      axios_promise.then(response => {
        this.shipmentsCountDayIndexed = response.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    }
  },
  watch: {
    productType() {
      if (this.productType && this.weekId) {
        this.loadData();
      }
    },
    weekId() {
      if (this.productType && this.weekId) {
        this.loadData();
      }
    },
    hideEmptyDestinationPlant() {
      if (this.productType && this.weekId) {
        this.loadData();
      }
    },
    doRefresh() {
      if (this.productType && this.weekId) {
        this.loadData();
      }
    }

  }
}
</script>

<style scoped>

.product_th,
.destination_plant_th {
  min-width: 100px;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.shipment_count_td, .shipment_count_tot_td {
  text-align: right;
}

.shipment_count_tot_td {
  text-align: right;
  font-weight: bold;
}

.shipment_count_tot_th {

  font-weight: bold;
}

</style>