/*

SCOPO: Mostrare una form minima di registrazione con email, password e i campi per toc e privacy
Tale form è ampliabile usando lo slot additionalFormFields (per aggiungere altri campi: nome, cognome, cf etc.)

Campi addizionali possono essere aggiunti tramite lo slot additional_fields.
Sul parent è possibile utilizzare come v-model i dati presenti nel form presente in questo componente.
Ma.. bisogna prima inizializzarli passandoli tramite la prop additionalFormFields.
Sul created tale prop verrà considerata per ampliare i dati presenti in form.

N.B in MjRegistration l'oggetto form non ha i sottocampi type e val, ma direttamente il valore

Esempio di utilizzo dello slot, nel parente, per aggiungere il campo cognome


TODO: da implementare la ricezione di altri campi sul b.e.

-----------------------------------------------------
HTTP: il componente si occupa di gestire tutte le comunicazioni http con il backend
-----------------------------------------------------

*/
<template>
  <v-container>
    <MjMessageCard
        :show="show_success_box"
        title="Ben Fatto!"
        sub_title="Registrazione avvenuta con Successo."
        action_icon="mdi-arrow-right"
        action_text="Vai al Login"
        action_link="/utente/login"
        footer_text="Riceverai a breve una mail di conferma.
              Se non la trovi nella tua casella tra qualche minuto controlla la posta indesiderata o spam"
    >
    </MjMessageCard>

    <v-form
        ref="form"
        v-show="show_form"
        v-model="valid"
        :lazy-validation="lazy"
    >
      <v-text-field
          outlined
          v-model="form.email"
          append-icon="mdi-email-outline"
          :rules="[rules.required, rules.email]"
          label="E-mail"
      >
      </v-text-field>
      <v-text-field
          outlined
          v-model="form.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          counter
          :rules="[rules.required, rules.min(8)]"
          label="Password"
          @click:append="showPassword = !showPassword"
      ></v-text-field>

      <slot name='additional_fields' :form="form">
      </slot>

      <v-checkbox v-model="toc_checkbox" :rules="[rules.must_be_true]">
        <template v-slot:label>
          <a class='small' :href="toc_url" target="_blank" @click.stop>Accetto Termini e Condizioni</a>
        </template>
      </v-checkbox>

      <v-checkbox v-model="privacy_checkbox" :rules="[rules.must_be_true]" class='mt-0'>
        <template v-slot:label>
          <a class='small' :href="privacy_url" target="_blank" @click.stop>Accetto Informativa Privacy</a>
        </template>
      </v-checkbox>

      <v-btn
          :disabled="!valid || registration_btn_loading"
          large
          color="primary"
          block
          class="mr-4"
          :loading="registration_btn_loading"
          type="submit"
          @click.prevent="submit"
      >Registrati
      </v-btn
      >

      <v-alert
          v-if="registration_error_show"
          type="warning"
          class="mt-2 py-2 warning"
      >
        <span v-text="registration_error_text"></span>
      </v-alert>
      <div class="mt-2">
        <router-link to="/utente/login">Hai già un account? Accedi</router-link>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import mj_axios from "@/mj_axios.js";
import mj_config from "@/mj_config.js";
import MjMessageCard from "@/components/MjMessageCard.vue";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";

export default {
  name: "MjRegistration",
  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build
  components: {MjMessageCard},
  mixins: [ValidationRulesMixin],
  props:['additionalFormFields'],
  created() {
    if (this.additionalFormFields)
    {Object.assign(this.form,this.additionalFormFields);}

    if (this.$route.query.email)
    {
      this.form.email =this.$route.query.email;
    }
  },
  computed: {
    toc_url() {
      return mj_config.toc_url();
    },
    privacy_url() {
      return mj_config.privacy_url();
    },
  },
  data: () => ({
    show_form: true,
    valid: true,
    toc_checkbox: false,
    privacy_checkbox: false,
    show_success_box: false,
    registration_btn_loading: false,
    registration_error_show: false,
    registration_error_text: "",
    showPassword: false,
    //form può essere ampliato dallo slot additional fields
    form: {
      password: "",
      email: "",
    },

    lazy: false,
  }),

  methods: {

    submit() {

      if (this.$refs.form.validate()) {
        this.registration_btn_loading = true;

        // const params = {
        //   email: this.form.email,
        //   password: this.form.password,
        //   password_confirmation: this.form.password,
        // };

        this.form.password_confirmation = this.form.password;

        mj_axios
            .post("/api/user/register", this.form)
            .then(() => {
              this.show_form = false;
              this.show_success_box = true;
            })
            .catch((e) => {
              this.registration_error_show = true;
              this.registration_error_text =
                  "Errore Durante la Registrazione: " + e.response.data.message;
            })
            .then(() => {
              // always executed
              this.registration_btn_loading = false;
            });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    form: {
      handler: function () {
        this.registration_error_show = false;
        return true;
      },
      deep: true,
    },
  },
};
</script>
