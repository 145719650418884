/**
Mostra tutti gli stati associati ad un viaggio, la struttura dati degli shipmentStatusChangedEvent è del tipo
[
{
"id": 1,
"shipment_id": 100,
"user_id": 1,
"old_status": "CANCELED",
"new_status": "PLANNED",
"created_at": "2022-07-18T17:10:42.000000Z",
"updated_at": "2022-07-18T17:10:42.000000Z"
},
...]

**/
<template>
  <v-container>
    <v-progress-linear v-show="loadingShipmentStatusChangedEvents >0 " indeterminate></v-progress-linear>
    <v-row v-if="shipmentStatusChangedEvents && shipmentStatusChangedEvents.length > 0" align="center" >
      <v-col v-for="(event,index) in shipmentStatusChangedEvents" :key="index" sm="1" class="mr-2 pt-1 pb-1">
        <MjEnumIcon :value="event.newStatus" :items-prop="shipmentStatusMjEnum"></MjEnumIcon>
      </v-col>
      <v-col sm="1" class="mr-2 pt-1 pb-1">
        <v-btn x-small fab color="primary" class="ml-sm-2" elevation="0" @click="showDetailDialog = true">
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div v-else-if="loadingShipmentStatusChangedEvents == 0">Nessun cambio di stato</div>
    <v-dialog v-model="showDetailDialog" max-width="500">
      <v-card>
        <v-card-title class="second_font overline text--secondary">Lista Eventi Cambio Stato
          <v-spacer>
          </v-spacer>
          <v-btn icon @click="showDetailDialog  = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table class="mj_simple_table mb-4">
            <thead class="smaller " >
            <th class="text-center pa-1 border_bottom" >Stato</th>
            <th class="text-center border_bottom">Nome Utente</th>
            <th class="text-center border_bottom">Ruoli</th>
            <th class="text-center border_bottom">Data</th>
            </thead>
            <tbody>
            <tr v-for="(event,index) in shipmentStatusChangedEvents" :key="index">
              <td class="text-center">
                <MjEnumIcon :value="event.newStatus" :items-prop="shipmentStatusMjEnum"></MjEnumIcon>
              </td>
              <td class="text-center">{{ event.userName }}
              </td>
              <td class="text-center">
                {{ event.roleNames.join(",") }}
              </td>
              <td class="text-center">{{


                  moment(event.updated_at).format('DD-MM-YYYY HH:mm:ss')
                }}
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import mj_axios from "@/mj_axios";
import moment from "moment";
import ReactivityMixin from "@/mixins/ReactivityMixin";
import MjEnumIcon from "@/components/MjEnumIcon";

export default {
  components: {MjEnumIcon},
  props: ['shipmentQrCode'],
  name: "ShipmentStatusEvents",
  mixins: [ReactivityMixin],
  mounted() {
    this.loadShipmentStatusMjEnum();
    this.loadShipmentStatusChangedEvents();
  },
  data: function () {
    return {
      moment: moment,
      showDetailDialog: false,
      shipmentStatusMjEnum: {},
      shipmentStatusChangedEvents: [],
      loadingShipmentStatusChangedEvents: 0,

    }
  },
  methods: {
    loadShipmentStatusMjEnum: function () {
      return mj_axios
          .get('/api/shipment_statuses')
          .then((response) => {
            this.shipmentStatusMjEnum = (response.data);
          });
    },
    loadShipmentStatusChangedEvents: function () {

      this.resetArray("shipmentStatusChangedEvents");
      if (this.shipmentQrCode) {
        this.loadingShipmentStatusChangedEvents++;
        const axios_promise = mj_axios.get(`/api/shipment/${encodeURIComponent(this.shipmentQrCode)}/status_changed_events`);
        axios_promise.then(response => {
          const shipmentStatusChangedEvents = response.data.data;
          this.updateArray("shipmentStatusChangedEvents", shipmentStatusChangedEvents);
        }).catch((error) => {

          if (error.response.statusCode != 404) {
            this.$store.commit("update_modal_dialog", {
              title: "Attenzione!!",
              text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
              visible: true,
            });
          } else {
            this.resetArray("shipmentStatusChangedEvents");
          }

        }).then(() => {
          this.loadingShipmentStatusChangedEvents--;
        });
        return axios_promise;
      }
    }
  },
  watch: {
    shipmentQrCode: function () {
      this.loadShipmentStatusChangedEvents();
    }
  }
}
</script>

<style scoped>

</style>