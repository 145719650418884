<template>
  <v-footer absolute fixed class="mt-4 caption footer" elevation="5">
    <v-spacer></v-spacer>
    <span>@{{app_name}} {{$store.getters.appVersion}}</span>
    <!-- <v-col class="text-right outlined" cols="12">@{{app_name}} {{$store.getters.appVersion}}</v-col> -->
  </v-footer>
</template>
<style scoped>
.footer{border-top:solid 1px #ddd; height: 44px;}
</style>
<script>
export default {
  name: "MjFooter",
  computed: {
    //per essere reattivo lo stato deve essere dentro computed, non dentro data.
    app_name() {
      return this.$store.state.client_settings.APPNAME;
    }
  },
 
};
</script>