<!-- Mostra le possibili tariffe tenendo conto degli eventuali filtri passati tramite tariffFilters-->
<template>

  <v-simple-table class="mj_simple_table">

    <thead>
    <tr>
      <th>
        <v-icon x-small color="warning" v-show="tariffFilters && tariffFilters.productType">mdi-lock-outline</v-icon>
        Prodotto
      </th>
      <th>
        <v-icon x-small color="warning" v-show="tariffFilters && tariffFilters.carrier">mdi-lock-outline</v-icon>
        Trasportatore
      </th>
      <th>
        <v-icon x-small color="warning" v-show="tariffFilters && tariffFilters.vehicleType">mdi-lock-outline</v-icon>
        Mezzo
      </th>
      <th>
        <v-icon x-small color="warning" v-show="tariffFilters && tariffFilters.packagingType">mdi-lock-outline</v-icon>
        Imballo
      </th>
      <th>
        <v-icon x-small color="warning" v-show="tariffFilters && tariffFilters.destinationPlant">mdi-lock-outline
        </v-icon>
        Imp. Destinazione
      </th>
      <th>Costo</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="loadingTariffs > 0">
      <td colspan="20" class="loader_td">
        <v-progress-linear indeterminate></v-progress-linear>
      </td>
    </tr>
    <template v-if="shipmentSuggestedTariffs.length > 0">
      <tr v-for="(shipmentTariff,index) in shipmentSuggestedTariffs" :key="index"
          :class="[selectedIndex == index ?  'primary  lighten-3' : '']">
        <td>{{ shipmentTariff.productType.cod }}</td>
        <td>{{ shipmentTariff.carrier.cod }}</td>
        <td>{{ shipmentTariff.vehicleType.cod }}</td>
        <td>{{ shipmentTariff.packagingType.cod }}</td>
        <td>{{ shipmentTariff.destinationPlant.cod }}</td>
        <td class="text-right">{{ mj_string_utils.moneyFormat(shipmentTariff.price,'IT',2,2,'EUR') }}</td>
        <td>
          <v-btn color="primary" class="lighten-2" x-small @click="selectedRow(index,shipmentTariff)"
                 :icon="selectedIndex == index">
            {{ selectedIndex == index ? '' : 'Scegli' }}
            <v-icon :right="selectedIndex != index">{{ selectedIndex == index ? 'mdi-close' : 'mdi-arrow-right' }}
            </v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
    <tr v-if="loadingTariffs == 0 && shipmentSuggestedTariffs.length == 0">
      <td colspan="20" class="text-center">
        Nessun elemento trovato nel tariffario
      </td>
    </tr>
    </tbody>
  </v-simple-table>

</template>

<script>
import mj_axios from "@/mj_axios";
import mj_string_utils from "@/misc/mj_string_utils";
import ReactivityMixin from "@/mixins/ReactivityMixin";

export default {
  name: "ShipmentTariffsOptimizer",
  mixins: [ReactivityMixin],
  props: {
    value: {type: Object},
    tariffFilters: {type: Object},
  },
  created() {
    this.loadTariffs();
  },
  data: function () {
    return {
      mj_string_utils,
      selectedIndex: null,
      loadingTariffs: 0,
      shipmentSuggestedTariffs: []
    }
  },
  methods: {
    loadTariffs() {
      this.loadingTariffs++;

      const params = {
        product_type_id: this.tariffFilters?.productType?.id,
        carrier_id: this.tariffFilters?.carrier?.id,
        vehicle_type_id: this.tariffFilters?.vehicleType?.id,
        packaging_type_id: this.tariffFilters?.packagingType?.id,
        destination_plant_id: this.tariffFilters?.destinationPlant?.id,
        order_by:'price'
      };

      const axios_promise = mj_axios.get('/api/tariffs', {params: params});
      axios_promise.then(response => {
        this.resetArray("shipmentSuggestedTariffs");
        // this.updateArray("tariffs", response.data.data)
        this.shipmentSuggestedTariffs = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento tariffe su shipmentTariffOptimizer, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingTariffs--;
      });
      return axios_promise;
    },
    selectedRow: function (index, shipmentTariff) {
      this.selectedIndex = this.selectedIndex == index ? null : index;
      this.$emit('input', this.selectedIndex == null ? null : shipmentTariff);
    },
  },
  watch: {
    'tariffFilters': {
      deep: true,
      handler() {
        this.loadTariffs();
      }
    }
  }

}
</script>

<style scoped>

</style>