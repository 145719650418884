/*
SCOPO DEL COMPONENTE: Raggruppare diversi filtri, fornendo unica interazione (ad esempio unico clear all per pulire tutti i filtri)
Le interazioni parent -> slot avvengono attraverso delle prop (nell'esempio reset all)
*/

<template>
  <v-expansion-panels v-model="panels_model">
    <v-expansion-panel class="mb-2 mt-2">
      <v-expansion-panel-header dense expand-icon="mdi-filter-variant" x-small class="text--secondary gradient_background text-uppercase border_bottom" >
        <v-layout row align-end >
          <div class="small caption ">
          Filtri di Ricerca
          </div>
        </v-layout>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="filters" :reset_all="reset_all"></slot>
        <slot name="bottom"></slot>

        <a class="small text--secondary text-decoration-underline float-end mt-4"  @click.stop="reset_all++;$emit('reset-all');">
          Resetta filtri
        </a>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: "MjFilterGroup",
  props: ["expand_filter"],
  computed: {},

  data: function () {
    return {
      reset_all: 0,
      panels_model: (this.expand_filter) ? 0 : false
    };
  },
  created() {
  },
  methods: {},
};
</script>