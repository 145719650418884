<template>
  <v-container fluid>
    <mj-soft-title class="mb-3 ml-3" icon="mdi-archive-outline"
                   :title="`Riepilogo Viaggi per Prodotto`"></mj-soft-title>
    <v-progress-linear indeterminate v-show="loadingProductTypes"></v-progress-linear>

    <WeekNavigator @change="changeWeek" class="mb-4"/>
    <v-layout row align-baseline class="mx-auto">
      <v-switch v-model="hideEmptyDestinationPlant" :true-value="1" :false-value="0"
                label="Nascondi destinazioni senza viaggi"></v-switch>
      <v-spacer></v-spacer>
      <v-btn icon @click="refresh()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-layout>
    <v-row>
      <v-col v-for="(productType,index) in productTypes" :key="index" sm="12" md="6">
        <v-card class="mt-4">
          <v-card-title class="white--text lighten-2 second_font overline py-0 mb-0"
                        :style="{backgroundColor:`#${productType.color_hex}`}">
            <v-icon small class="mr-1">mdi-package-variant</v-icon>
            {{
              productType.cod
            }}
          </v-card-title>
          <v-card-text class="pa-0">
            <DestinationPlantShipmentsWeek :product-type="productType" :week-id="currentWeekId"
                                           :hide-empty-destination-plant="hideEmptyDestinationPlant"
                                           :doRefresh="doRefresh"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import MjSoftTitle from "@/components/MjSoftTitle";
import DestinationPlantShipmentsWeek from "@/components/custom/DestinationPlantShipmentsWeek";
import ProductTypeMixin from "@/mixins/entities/ProductTypeMixin";
import WeekNavigator from "@/components/custom/WeekNavigator";


export default {
  name: "PlanningShipmentProductReport",
  components: {DestinationPlantShipmentsWeek, MjSoftTitle, WeekNavigator},
  mixins: [ProductTypeMixin],
  created() {
    this.loadProductTypes();
  },
  data: function () {
    return {
      loadingShipmentsProductReport: 0,
      currentWeekId: null,
      hideEmptyDestinationPlant: false,
      doRefresh: 0,
    }
  },
  methods: {
    refresh() {
      this.doRefresh++;
    },
    changeWeek: function (weekId) {
      this.currentWeekId = weekId;
    }
  }

}
</script>

<style scoped>

</style>