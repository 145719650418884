<template>
  <v-container>
    <!--    VIEW TITLE AND REFRESH-->
    <v-container>
      <v-layout row justify-center align-center class="mx-0">
        <mj-soft-title title="Monitor Viaggi Attivi"></mj-soft-title>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="loadShipments">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-layout>
    </v-container>
    <!--    INTERVAL UPDATER-->
    <MjIntervalUpdater :updateFunction="this.loadShipments" :showProgressBar="false"
                       :updateIntervalMillis="1000*60"></MjIntervalUpdater>
    <!-- DATE PICKER AND ARROWS   -->
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn icon color="primary" @click="setPrevDay">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3">
        <MjDateOrTimePicker mode="date"
                            display_format="DD-MM-YYYY"
                            model_format="YYYY-MM-DD"
                            v-model="selectedLoadingDate"
        ></MjDateOrTimePicker>
      </v-col>
      <v-col cols="1">
        <v-btn icon color="primary" @click="setNextDay">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!--    FILTERS: STATUS, TEXT SEARCH AND QR-->
    <v-layout align-center justify-start row class="mx-2">
      <MjToggleButtons class="mr-sm-2" :multiple="true" elevation="2" load_items_url="/api/shipment_statuses"
                       item_description_field="text" item_value_field="value"
                       description_as_tooltip
                       v-model="selectedShipmentStatuses"
                       @input="loadShipments"
      ></MjToggleButtons>

      <v-text-field class="mr-2" label="Cerca Viaggio" v-model="qrCodeTextField" hide-details append-icon="mdi-magnify"
                    clearable solo dense
                    height="47px"
                    @click:append="() => {if (qrCodeTextField && qrCodeTextField.length >0) {this.currentShipmentQrCode=qrCodeTextField; this.showShipmentDialog=true}}"
                    @change="() => {if (qrCodeTextField && qrCodeTextField.length >0) {this.currentShipmentQrCode=qrCodeTextField; this.showShipmentDialog=true}}"></v-text-field>
      <v-btn height="47px" class="primary" elevation="4" @click="qrScannerShow=!qrScannerShow">
        <v-icon>mdi-qrcode-scan</v-icon>
      </v-btn>
      <MjQrScannerDialog v-model="qrScannerShow" @decode="onDecodeQrScanner"></MjQrScannerDialog>
    </v-layout>
    <!-- TABLE RESULTS CONTENT-->
    <v-simple-table class="mt-6 mj_simple_table" fixed-header>
    <!--      TABLE HEADER-->
      <thead>
      <tr>
        <th v-for="(header,index) in shipmentsHeader" :key="index" :class="'text-'+header['align']">{{
            header['text']
          }}
        </th>
      </tr>
      </thead>
      <tbody>
      <!--  LOADER -->
      <tr v-if="!loadingShipments && (!shipments || shipments.length == 0)">
        <td colspan="20" class="text-center second_font">
          Nessun Viaggio trovato
        </td>
      </tr>
      <tr v-show="loadingShipments > 0 ">
        <td colspan="20" class="loader_td">
          <v-progress-linear indeterminate></v-progress-linear>
        </td>
      </tr>
      <!--      ROWs RESULT TRs-->
      <template v-for="(shipment,index) in shipments">
        <tr :key="'s'+index" @click="currentShipmentQrCode=shipment['shipmentQrCode'];showShipmentDialog=true">
          <td class="text-right">
            {{ shipment["shipmentQrCode"] }}
          </td>
          <td class="text-center">
            <MjEnumIcon :value="shipment['status']" :items-prop="shipmentStatusMjEnum"></MjEnumIcon>
          </td>
          <td class="text-center">
            {{ shipment["productTypeCod"] }}
          </td>
          <td class="text-center">
            {{ shipment["productionPlantCod"] }}
          </td>
          <td class="text-center">
            {{ shipment["carrierName"] }}
          </td>
          <td class="text-center">
            {{ shipment["destinationPlantCod"] }}
          </td>
          <td class="text-left">
            <v-tooltip v-if="shipment['note']" top>
              <template v-slot:activator="{on,attrs}">
                <v-icon v-bind="attrs"
                        v-on="on"
                        color="teal">mdi-note-edit-outline
                </v-icon>
              </template>
              <span>
                {{ shipment["note"] }}
              </span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      </tbody>
    </v-simple-table>
    <!--    SHIPMENT DETAIL DIALOG-->
    <ShipmentsDialog v-model="showShipmentDialog" :shipmentQrCode="currentShipmentQrCode"
                     @shipmentStatusChanged="loadShipments"></ShipmentsDialog>

    <!--    SNACKBAR FOR COMUNICATIONS-->
    <v-snackbar app multi-line bottom right color="teal" outlined v-model="snackBarShow" elevation="3">
      {{ snackBarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import MjDateOrTimePicker from "@/components/form/MjDateOrTimePicker";
import moment from "moment";
import MjSoftTitle from "@/components/MjSoftTitle";
import MjToggleButtons from "@/components/form/MjToggleButtons";
import MjEnumIcon from "@/components/MjEnumIcon";
import mj_axios from "@/mj_axios";
import ShipmentsDialog from "@/views/shipments/ShipmentDialog";

import MjIntervalUpdater from "@/components/MjIntervalUpdater";
import MjQrScannerDialog from "@/components/MjQrScannerDialog";
import ShipmentStatusMjEnumMixin from "@/mixins/entities/ShipmentStatusMjEnumMixin";

export default {
  name: "ShipmentsView",
  components: {
    MjIntervalUpdater, ShipmentsDialog, MjToggleButtons, MjSoftTitle,
    MjDateOrTimePicker, MjEnumIcon, MjQrScannerDialog
  },
  mixins:[ShipmentStatusMjEnumMixin],
  mounted() {
    this.init();
  },

  data: () => ({
    selectedLoadingDate: moment().format('YYYY-MM-DD'),
    qrCodeTextField: '',
    qrScannerShow: false,
    snackBarShow: false,
    snackBarText: '',
    selectedShipmentStatuses: [],
    loadingShipments: 0,
    currentShipmentQrCode: null,
    showShipmentDialog: false,


    shipmentsHeader: [{text: 'Codice', align: 'right'},
      {text: 'Stato', align: 'center'},
      {text: 'Prodotto', align: 'center'},
      {text: 'Produttore', align: 'center'},
      {text: 'Trasportatore', align: 'center'},
      {text: 'Destino', align: 'center'},
      {text: 'Note', align: 'left'}],
    shipments: [],
  }),
  methods: {
    init:function(){
      this.selectedShipmentStatuses = this.$route.query["shipment_status"] ? this.$route.query["shipment_status"].split(',') : [];
      this.loadShipmentStatusMjEnum();
      this.loadShipments();
    },
    setNextDay: function () {
      this.selectedLoadingDate = moment(this.selectedLoadingDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    },
    setPrevDay: function () {
      this.selectedLoadingDate = moment(this.selectedLoadingDate, 'YYYY-MM-DD').add(-1, 'days').format('YYYY-MM-DD');
    },

    loadShipments: function () {

      this.loadingShipments++;
      const params = {loading_date: this.selectedLoadingDate};

      if (this.selectedShipmentStatuses !== undefined) {
        params.statuses = this.selectedShipmentStatuses;
      }

      const axios_promise = mj_axios.get('/api/monitor/shipments', {params: params});
      axios_promise.then(response => {
        this.shipments = response.data.data;
      }).catch((e) => {
        const message = e.response.data.message
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: `Errore di caricamento viaggi,${message},se il problema persiste contattare l'assistenza tecnica.`,
          visible: true,
        });
      }).then(() => {
        this.loadingShipments--;
      });
      return axios_promise;
    },
    onDecodeQrScanner: function (val) {
      this.qrScannerShow = false;
      this.currentShipmentQrCode = val;
      this.showShipmentDialog = true;
      this.qrCodeTextField = null;
    },
  },
  watch:
      {
        'selectedLoadingDate': function () {
          const selectedDateMoment = moment(this.selectedLoadingDate, 'YYYY-MM-DD');
          if (selectedDateMoment.isBefore(moment(), 'day')) {
            this.selectedLoadingDate = moment().format('YYYY-MM-DD');
            this.snackBarText = "Non è possibile consultare date precedenti a quella odierna. Per consultare lo \"storico viaggi\" usa l'apposita funzione"
            this.snackBarShow = true;
          } else {

            this.loadShipments();
          }
        },
          '$route.params.query': {
            handler: function() {
              this.init();
            },
            deep: true,
                immediate: true
          }

      }
}
</script>

<style scoped>

</style>