const mj_string_utils = {
    camelToSnakeCase(str) {
        return str.split(/(?=[A-Z])/).join('_').toLowerCase();
    },
    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    //Prima di usare questo metodo valuta di usare un formatter nei data del component.. cosi non inizializzi ogni volta il formatter
    moneyFormat(number, locale, minimumFractionDigits, maximumFractionDigits, currency = "EUR") {
        // Create our number formatter.
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,

            // These options are needed to round to whole numbers if that's what you want.
            minimumFractionDigits: minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
        });

        return formatter.format(number); /* $2,500.00 */

    }
    , humanFileSize(bytes, si = false, dp = 1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }

}

export default mj_string_utils;