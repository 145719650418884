<template>
  <v-container fill-height>
    <v-card class="mx-auto text-center" width="75vw" justify="center">
      <v-card-title class="  grey lighten-4 justify-center border_bottom text--disabled second_font">Ops! Qualcosa è andato Storto</v-card-title>
      <v-card-text class="mt-6">

        <p class="font-weight-bold body-1 warning--text">" {{message}} "</p>

        <div class="caption mt-4">
              Per ogni esigenza non esitare a contattare l'assistenza tecnica 
              <a :href='mail_assistenza_link'>{{mail_assistenza}}</a>
            </div>
      </v-card-text>
      <v-card-actions class="justify-center mt-3"><v-btn class="ma-2" color="primary" to="/">
        Torna alla Home
        <v-icon dark right>mdi-home</v-icon>
      </v-btn></v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      message: this.$route.query.msg || "",
    };
  },
  computed:{
    mail_assistenza: function(){return this.$store.state.client_settings.MAIL_ASSISTENZA},
    mail_assistenza_link:function(){return 'mailto:'+this.mail_assistenza;}
  }
};
</script>
