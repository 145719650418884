import moment from "moment";

const mj_date_utils = {
    getNextWeekId() {
        const nextWeek = moment().startOf('isoWeek').add(1,'week');
        return nextWeek.format('DD-MM-YYYY');
    },

    getNextWeekFromToDescription(){
         const nextWeek = moment().add(1,'weeks').locale('it');
         return `Da ${nextWeek.startOf('isoWeek').format('DD-MMM-YY')} a ${nextWeek.endOf('week').format('DD-MMM-YY')}`
    },

    getMomentDate(startDateId,dayIndex){
        const momentDate =  moment(startDateId,'DD-MM-YYYY').locale('it').startOf('day');
        if (dayIndex)
        {
            momentDate.add(dayIndex,'days');
        }
        return momentDate;
    },

    getMomentWeekStart(startDateId){
        const date = this.getMomentDate(startDateId);
        return date.startOf('isoWeek');
    },

    getMomentWeekEnd(startDateId){
        const date = this.getMomentDate(startDateId);
        return date.endOf('isoWeek');
    },

    prevWeekId(startDateId){
        const date = this.getMomentDate(startDateId);
        date.add(-1,'week');
        return (date.locale('it').format('DD-MM-YYYY'));
    },

    nextWeekId(startDateId){
        const date = this.getMomentDate(startDateId);
        date.add(1,'week');
        return (date.locale('it').format('DD-MM-YYYY'));
    }
}

export default mj_date_utils;