<template>
  <v-container class="mx-4">
    <div >
      <h2 class="text-uppercase text--secondary">{{ this.$store.state.client_settings.APPNAME }}</h2>
      <p class="  font-weight text--disabled overline">
        Benvenuto {{ this.$store.state.token_obj.name }}!
      </p>
      <p class="  font-weight text--disabled second_font">
        Per accedere alle funzioni del software usa il menu laterale o accedi alle funzioni tramite i collegamenti che
        troverai nella parte inferiore di questa schermata
      </p>
    </div>
    <MjMenuCards size='large' menu-items-api-url="/api/menu_items" class="ml-0 pl-0"/>
  </v-container>
</template>

<script>


import MjMenuCards from "@/components/MjMenuCards";

export default {
  name: "Menu",
  components: {MjMenuCards},

};
</script>
