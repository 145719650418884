import mj_axios from "@/mj_axios";

const shipmentMixin = {

    data: function () {
        return {
            shipments: null,
            loadingShipments: 0,
            shipmentsCountDayIndexed: null,
            loadingShipmentsCountDayIndexed: 0,
            deletingShipments: 0,
            insertingShipment: 0,
        };
    },

    methods: {
        loadShipmentsCountDayIndexedFor(productionPlantId, weekId) {
            this.loadingShipmentsCountDayIndexed++;
            const axios_promise = mj_axios.get(`/api/production_plants/${productionPlantId}/shipments_count_day_indexed?week_id=${weekId}`);
            axios_promise.then(response => {
                this.shipmentsCountDayIndexed = response.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento viaggi, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingShipmentsCountDayIndexed--;
            });
            return axios_promise;
        },
        loadShipments(loadingDate, productionPlantId, productTypeId,) {
            this.loadingShipments++;
            const params = {
                loading_date: loadingDate,
                product_type_id: productTypeId,
                production_plant_id: productionPlantId
            }
            const axios_promise = mj_axios.get(`/api/shipments`, {params: params});
            axios_promise.then(response => {
                this.shipments = response.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento viaggi, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingShipments--;
            });
            return axios_promise;
        },
        deleteShipment(shipmentId) {
            this.deletingShipments++;
            const shipmentIds = [shipmentId];
            const axios_promise = mj_axios.delete(`/api/shipments/${JSON.stringify(shipmentIds)}`);
            axios_promise.then(() => {

            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore eliminazione viaggi, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.deletingShipments--;
            });
            return axios_promise;
        },
        insertShipment(shipment) {
            this.insertingShipment++;

            const axios_promise = mj_axios.post(`/api/shipments`,shipment);
            axios_promise.then(() => {

            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore nella creazione dei viaggi, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.insertingShipment--;
            });
            return axios_promise;
        },

    },

};

export default shipmentMixin;
