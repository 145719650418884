<template>
  <v-container>
    <MjEntityForm :title="'Ruolo ' + (($route.params.id) ? $route.params.id : 'Nuovo') " :form="form" :apiUrl="apiUrl">
      <template #fields="slotProps">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="form.id.val" label="Id Ruolo" disabled required></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.name.val"
              :rules="[rules.required]"              
              label="Nome Ruolo"
              required
              :disabled="slotProps.editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-combobox
              v-model="form.permissions.val"
              item-text="name"
              item-value="id"
              :items="slotProps.selects.permissions"
              deletable-chips
              label="Permessi"
              multiple
              chips
            ></v-combobox>
          </v-col>
        </v-row>

      </template>
    </MjEntityForm>
  </v-container>
</template>

<script>
import MjEntityForm from "@/components/form/MjEntityForm.vue";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";

export default {
  name: "RoleFormView",
  mixins: [ValidationRulesMixin],
  components: { MjEntityForm },
  data: () => ({
    form: {
      id: { type: "integer", val: null},
      name: { type: "string", val: "" },
      permissions: { type: "array", apiUrl:"/api/user/permissions", loadSelect:true, val: [] }
    },
    apiUrl: "/api/user/roles"
  }),

  methods: {}
};
</script>
