/*
La Registration View Mostra un testo standard con il titolo del'app e l'invito a registrarsi.

Si appoggia al componente MjRegistration per ottenere i dati e comunicarli tramite ajax al b.e.
Il componente MjRegistration è espandibile grazie allo slot: Per cui di default avrà i campi email e password, con lo slot
potrai aggiungere nome, cognome etc.

Esempio di uso degli slot per aggiungere il nome

   <MjRegistration>
         <template v-slot:additional_fields="{ form }">
            <v-text-field outlined label="Nome" v-model='form["nome"]'>
            </v-text-field>
         </template>
    </MjRegistration>

Se vuoi aggiungere lo slot conviene usare una RegistrationView Custom (inserendola nell'apposita dir)
e configurare lo slot.
*/

<template>
  <v-container fill-height fluid>
    <MjAlreadyLoggedDialog></MjAlreadyLoggedDialog>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8" lg="4">
        <h1 class="text-center mb-2">{{ this.$store.state.client_settings.APPNAME }}</h1>
        <h3 class="text-center mb-4">Iscriviti per creare il tuo account</h3>
        <MjRegistration>

        </MjRegistration>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MjRegistration from "@/components/MjRegistration.vue";
import MjAlreadyLoggedDialog from "@/components/MjAlreadyLoggedDialog.vue";

export default {
  name: "RegistrationView",
  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build
  components: {MjRegistration,MjAlreadyLoggedDialog},
  data: () => ({

  }),

  methods: {}
};
</script>
