<!--FUNZIONA ATTRAVERSO VUEX IN MODO DA GESTIRLO FUORI IL ROUTER-->
<template>
  <div class="text-center">
    <v-dialog v-model="dialog_show" width="500" :fullscreen="fullscreen">
      <v-card>
        <v-card-title class="headline" primary-title>{{
          dialog_title
        }}</v-card-title>

        <v-card-text class="pt-4 pb-4">
          <div v-html="dialog_text"></div>
          <slot></slot>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              v-if="cancel_button_visible"
              color="default"
              text
              @click="$emit('cancel-from-dialog')"
          >{{ cancel_button_text }}
          </v-btn>
          <v-btn
            v-if="confirm_button_visible"
            color="primary"
            text
            @click="$emit('confirm-from-dialog')"
            >{{ confirm_button_text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MjConfirmDialog",
  props: {
    value: {},
    dialog_title: { default: "Informazione" },
    dialog_text: { default: "" },
    confirm_button_text: { default: "Conferma" },
    cancel_button_text: { default: "Annulla" },
    confirm_button_visible: { default: true },
    cancel_button_visible: { default: true },
    fullscreen: { default: false }
  },
  data() {
    return {
      dialog_show: this.value,
    };
  },
  methods: {},
  watch: {
    value: function (val) {
      this.dialog_show = val;
    },
  },
};
</script>