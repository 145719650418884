<template>
  <VueRecaptcha
    :sitekey="siteKey"
    :loadRecaptchaScript="true"
    class="mb-4"
    :theme="theme"
    @verify="onCaptchaVerify"
    @expired="onCaptchaExpired"
  ></VueRecaptcha>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import mj_config from "@/mj_config.js";

export default {
  name: "Recaptcha",
  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build
  components: { VueRecaptcha },
  computed: {
    theme:function(){
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    }
  },
  data: () => ({
    siteKey:mj_config.googleSiteKey(),
    // theme: this.$vuetify.theme.dark ? 'dark' : 'light'
  }),
  methods: {
    onCaptchaVerify: function(token) {
      this.$emit('update:captchaToken', token);
    },
    onCaptchaExpired: function() {
      this.$emit('update:captchaToken', null);
    },
     resetRecaptcha () {
      this.$refs.recaptcha.reset() // Direct call reset method
    }
  }
};
</script>
