import mj_axios from "@/mj_axios";

const intermediaryMixin = {

    data: function () {
        return {
            intermediaries: [],
            loadingIntermediaries: 0,
            destinationPlantsIntermediaries: [],
            loadingDestinationPlantsIntermediaries:0,
        };
    },

    methods: {
        loadIntermediaries() {
            this.loadingIntermediaries++;
            const axios_promise = mj_axios.get('/api/intermediaries');
            axios_promise.then(response => {
                this.intermediaries = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento Intermediari, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingIntermediaries--;
            });
            return axios_promise;
        },
        loadDestinationPlantIntermediaries(destinationPlantId) {
            this.loadingDestinationPlantsIntermediaries++;
            const axios_promise = mj_axios.get(`/api/destination_plants/${destinationPlantId}/intermediaries`);
            axios_promise.then(response => {
                this.destinationPlantsIntermediaries = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento Intermediari per Impianto di destinazione, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingDestinationPlantsIntermediaries--;
            });
            return axios_promise;
        },
    },

};

export default intermediaryMixin;
