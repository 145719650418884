<template>
  <div class="text-center">

    <v-btn @click="prevWeek" icon >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    Settimana del
    {{ mj_date_utils.getMomentWeekStart(this.selectedWeekId).format('DD-MM-YY') }}

    <v-tooltip
        v-model="showTooltip"
        right
        open-on-click
    >
      <template v-slot:activator="{}">
        <v-btn
            icon
            x-small
            @click="showTooltip = !showTooltip"
            :loading="loading > 0"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>
      <span>Settimana dal: {{ mj_date_utils.getMomentWeekStart(this.selectedWeekId).format('DD-MM-YYYY') }}<br>
        al {{ mj_date_utils.getMomentWeekEnd(this.selectedWeekId).format('DD-MM-YYYY HH:mm:ss') }}
        <br><br>
          ID sul DB: {{ this.selectedWeekId }}
        </span>

    </v-tooltip>
    <v-btn @click="nextWeek" icon>
      <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
import mj_date_utils from "@/misc/mj_date_utils";

export default {
  name: "WeekNavigator",
  mounted() {
    this.$emit('change', this.selectedWeekId);
  },
  props: ['loading'],
  data: function () {
    return {
      mj_date_utils: mj_date_utils,
      showTooltip: false,
      selectedWeekId: mj_date_utils.getNextWeekId(),
    }
  }, methods: {
    prevWeek: function () {
      this.selectedWeekId = mj_date_utils.prevWeekId(this.selectedWeekId);
      this.$emit('change', this.selectedWeekId);
    }
    ,
    nextWeek: function () {
      this.selectedWeekId = mj_date_utils.nextWeekId(this.selectedWeekId);
      this.$emit('change', this.selectedWeekId);
    }
    ,
  }

}
</script>

<style scoped>

</style>