<template>
  <v-container>
    <MjEntityForm :title="'Tipo Veicolo ' + (($route.params.id) ? $route.params.id : 'Nuovo') " :form="form"
                  :apiUrl="apiUrl">
      <!--eslint-disable-next-line vue/no-unused-vars-->
      <template #fields="slotProps">
        <v-row>
          <v-col cols='12' md='2'>
            <v-text-field
                label='Alias'
                v-model='form.cod.val'
                :rules="[rules.required,rules.max(6)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='5'>
            <v-text-field
                label='Nome'
                v-model='form.name.val'
                :rules="[rules.max(64)]">
            </v-text-field>
          </v-col>
          <v-col cols='12'>
            <v-textarea
                label='Nota'
                v-model='form.note.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
        </v-row>

      </template>
    </MjEntityForm>
  </v-container>
</template>

<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjEntityForm from "@/components/form/MjEntityForm";


export default {
  name: "VehicleTypeFormView",
  mixins: [ValidationRulesMixin],
  components: {MjEntityForm,},
  data: () => ({
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      name: {type: 'string', val: '', default_val: ''},
      note: {type: 'text', val: '', default_val: ''},
    },
    apiUrl: "/api/vehicle_types"
  }),
};
</script>
