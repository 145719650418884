<template>
  <v-container fluid>

    <MjTableItems
        ref="tableCarrier"
        model-name-prop="Carrier"
        resource-collection-name="CarrierResourceListCollection"
    >
      <template #mj_top_toolbar_prepend="slotProps">
        <v-menu
            :close-on-content-click="false"
            v-model="showProductTypes"
        >
          <template v-slot:activator="{ on:menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on:tooltip }">
                <v-btn
                    v-show="slotProps.selected.length > 0"
                    icon
                    color="blue white--text"
                    class=""
                    v-on="{...tooltip, ...menu}"
                    v-bind="attrs"
                >
                  <v-icon>mdi-package-variant</v-icon>
                </v-btn>
              </template>
              <span>Associa Tipi Prodotti</span>
            </v-tooltip>
          </template>
          <MjSelectableList
              title="Tipologia prodotti"
              load_items_url="/api/product_types"
              belong_url="/api/parents_has_children"
              pivot_table="carriers_has_product_types"
              parent_col_name="carrier_id"
              child_col_name="product_type_id"
              parent_model_name="Carrier"
              child_model_name="ProductType"
              relation_name="productTypes"
              list_title_field="cod"
              list_subtitle_field="name"
              max_height="300px"
              :exclusive="false"
              :parent_ids="slotProps.selected_ids"
              @close-popup="showProductTypes = false; $refs.tableCarrier.loadItems(true);"
          />
        </v-menu>
        <v-menu
            :close-on-content-click="false"
            v-model="showVehicleTypes"
        >
          <template v-slot:activator="{ on:menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on:tooltip }">
                <v-btn
                    v-show="slotProps.selected.length > 0"
                    icon
                    color="purple white--text"
                    v-on="{...tooltip, ...menu}"
                    v-bind="attrs"
                >
                  <v-icon>mdi-truck-trailer</v-icon>
                </v-btn>
              </template>
              <span>Associa Veicoli</span>
            </v-tooltip>
          </template>
          <MjSelectableList
              title="Veicoli"
              load_items_url="/api/vehicle_types"
              belong_url="/api/parents_has_children"
              pivot_table="carriers_has_vehicle_types"
              parent_col_name="carrier_id"
              child_col_name="vehicle_type_id"
              parent_model_name="Carrier"
              child_model_name="VehicleType"
              relation_name="vehicleTypes"
              list_title_field="cod"
              list_subtitle_field="name"
              max_height="300px"
              :exclusive="false"
              :parent_ids="slotProps.selected_ids"
              @close-popup="showVehicleTypes = false; $refs.tableCarrier.loadItems(true);"
          />
        </v-menu>
        <v-menu
            :close-on-content-click="false"
            v-model="showDestinationPlants"
        >
          <template v-slot:activator="{ on:menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on:tooltip }">
                <v-btn
                    v-show="slotProps.selected.length > 0"
                    icon
                    color="red darken-2 white--text"
                    class=""
                    v-on="{...tooltip, ...menu}"
                    v-bind="attrs"
                >
                  <v-icon>mdi-flag-off-outline</v-icon>
                </v-btn>
              </template>
              <span>Impianti Destino in Blacklist per il trasportatore/i</span>
            </v-tooltip>
          </template>
          <MjSelectableList
              title="Impianti di Destino"
              load_items_url="/api/destination_plants"
              belong_url="/api/parents_has_children"
              pivot_table="carrier_in_blacklist_for_destination_plant"
              parent_col_name="carrier_id"
              child_col_name="destination_plant_id"
              parent_model_name="Carrier"
              child_model_name="DestinationPlant"
              relation_name="destinationPlantsBlacklisted"
              list_title_field="cod"
              list_subtitle_field="name"
              max_height="300px"
              :exclusive="false"
              :parent_ids="slotProps.selected_ids"
              @close-popup="showDestinationPlants = false; $refs.tableCarrier.loadItems(true);"
          />
        </v-menu>
            <v-tooltip top>
              <template v-slot:activator="{ on:tooltip }">
                <v-btn
                    v-show="slotProps.selected.length > 0"
                    icon
                    color="orange darken-2 white--text"
                    class=""
                    v-on="{...tooltip}"
                    @click="mailShipmentNextWeekPlanning(slotProps.selected)"
                    :loading="sendingWeeklyReportMail>0"
                >
                  <v-icon>mdi-calendar-end</v-icon>
                </v-btn>
              </template>
              <span>Invia Pianificazione per settimana prossima</span>
            </v-tooltip>
      </template>
    </MjTableItems>
  </v-container>
</template>

<script>
import MjTableItems from "@/components/MjTableItems";
import MjSelectableList from "@/components/MjSelectableList";
import mj_date_utils from "@/misc/mj_date_utils";
import mj_axios from "@/mj_axios";

export default {
  name: "TableCarriers",
  components: {MjTableItems, MjSelectableList},
  data: () => ({
    showProductTypes: false,
    showVehicleTypes: false,
    showDestinationPlants: false,
    sendingWeeklyReportMail: 0,
  }),
  methods:{
    mailShipmentNextWeekPlanning(carriers){

      const nextWeekId = mj_date_utils.getNextWeekId();
      const carriersIds = (JSON.stringify(carriers.map((carrier)=>carrier.id)));
      this.sendingWeeklyReportMail++;

      const params = {weekId: nextWeekId};
      const axios_promise = mj_axios.post(`/api/carriers/${carriersIds}/actions/mail_weekly_report`, params);
      axios_promise.then(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Info",
          text: "L'invio delle mail è stato schedulato, a breve i destinatari riceveranno le mail. Il sistema invia email solo in caso il trasportatore abbia viaggi assegnati nella pianificazione.",
          visible: true,
        });
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di invio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.sendingWeeklyReportMail--;
      });
      return axios_promise;

    }
  }
}
</script>

<style scoped>

</style>