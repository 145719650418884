<template>
  <v-dialog v-model="qrScannerShow">
    <v-card>
      <v-card-title class="second_font text--secondary">Qr Scanner
        <v-spacer></v-spacer>
        <v-btn icon @click="qrScannerShow=false;qrScannerInitializing=false;  $emit('input', qrScannerShow)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="qrScannerInitializing">
        <v-skeleton-loader type="image" width="auto" height="420px"></v-skeleton-loader>
      </v-card-text>
      <v-card-text :class="[qrScannerInitializing || showQrScannerError ? 'height_zero' : '']">
        <!-- v-if rende possibile re-scannerizzare lo stesso qr due volte consecutive altrimenti usa v-show -->
        <qrcode-stream class="scanner mt-3" v-if="qrScannerShow" @init="onQrScannerInit"
                       @decode="(val)=>{qrScannerInitializing=false;$emit('decode',val)}">
        </qrcode-stream>
      </v-card-text>
      <v-card-text v-if="showQrScannerError">
        <v-alert outlined type="warning">
          {{ this.qrScannerErrorText }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {QrcodeStream} from "vue-qrcode-reader";

export default {
  name: "MjQrScannerDialog",
  props: ['value'],
  components: {QrcodeStream},
  data() {
    return {
      qrScannerShow: this.value,
      qrScannerInitializing: true,
      showQrScannerError: false,
      qrScannerErrorText: '',
    }
  },
  methods: {
    async onQrScannerInit(promise) {
      this.showQrScannerError = false;
      this.qrScannerInitializing = true;
      try {
        // eslint-disable-next-line no-unused-vars
        const {capabilities} = await promise

        // successfully initialized
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
          this.qrScannerErrorText = 'Utente non ha consentito al software l\'uso della fotocamera';
        } else if (error.name === 'NotFoundError') {
          this.qrScannerErrorText = 'Non esiste una fotocamera disponibile in questo dispositivo';
          // no suitable camera device installed
        } else if (error.name === 'NotSupportedError') {
          this.qrScannerErrorText = 'Non posso utilizzare la fotocamera. Verifica che il sito sia servito in modalità https';
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
          this.qrScannerErrorText = 'Non posso utilizzare la fotocamera. Verifica che già non sia in uso da altro software';
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
          this.qrScannerErrorText = 'Non posso utilizzare la fotocamera. Hai forse richiesto fotocamera frontale ma non è disponibile?';
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
          this.qrScannerErrorText = 'Non posso utilizzare la fotocamera. E\' probabile che questo browser non supporti la funzionalità, prova ad usare Google Chrome';
        }
        this.showQrScannerError = true;
      } finally {
        // hide loading indicator
        this.qrScannerInitializing = false;
      }
    }
  },
  watch: {
    'value': function (val) {
      this.qrScannerShow = val;
    }
  }
}
</script>