<template>
  <v-container fluid>

    <MjTableItems
        ref="tableShipment"
        model-name-prop="Shipment"
        resource-collection-name="ShipmentResourceListCollection"
        sort_by="data carico"
        :sort_desc="true"
        :custom-edit-item="openShipmentDialog"
    >
      <template #mj_filters_section="slotProps">
        <MjFilterGroup             @reset-all="
            $refs.tableShipment.filters = [];
            $refs.tableShipment.loadItems(true);
          ">
          <template #filters="filterSlotProps">

            <MjDateIntervalFilter
                label-from="Da"
                label-to="A"
                time-model-format="HH:mm:ss"
                time-display-format="HH:mm"
                date-model-format="YYYY-MM-DD"
                date-display-format="DD/MM/YYYY"
                :value-from="start_of_current_month"
                :value-to="end_of_current_month"
                mode="date"
                glue="and"
                @change="slotProps.updateFilters"
                :silent_reset="filterSlotProps.reset_all"
                filter_name="loading_date"
                class="mb-0 pb-0"
            />
            <MjToggleFilter class="my-0 py-0"
                            :multiple="true"
                            filter_name="status"
                            glue="and"
                            :small="true"
                            elevation="2"
                            load_items_url="/api/shipment_statuses"
                            item_description_field="text" item_value_field="value"
                            description_as_tooltip
                            :silent_reset="filterSlotProps.reset_all"
                            @change="slotProps.updateFilters"
            ></MjToggleFilter>


          </template>
          {{ slotProps }}
        </MjFilterGroup>
      </template>
      <template #mj_after_search_bar="">

        <v-btn icon small color="primary" @click="qrScannerShow=!qrScannerShow">
          <v-icon>mdi-qrcode-scan</v-icon>
        </v-btn>
        <MjQrScannerDialog v-model="qrScannerShow" @decode="onDecodeQrScanner"></MjQrScannerDialog>

      </template>
    </MjTableItems>
    <!--    SHIPMENT DETAIL DIALOG-->
    <ShipmentDialog v-model="showShipmentDialog" :shipmentQrCode="currentShipmentQrCode"
                     @shipmentStatusChanged="$refs.tableShipment.loadItems()"></ShipmentDialog>
  </v-container>
</template>

<script>
import MjTableItems from "@/components/MjTableItems";
import MjFilterGroup from "@/components/filters/MjFilterGroup";
import MjToggleFilter from "@/components/filters/MjToggleFilter";
import MjDateIntervalFilter from "@/components/filters/MjDateIntervalFilter";
import MjQrScannerDialog from "@/components/MjQrScannerDialog";
import moment from "moment"
import ShipmentDialog from "@/views/shipments/ShipmentDialog";


export default {
  name: "TableShipments",
  components: {MjDateIntervalFilter, MjFilterGroup, MjTableItems, MjToggleFilter,MjQrScannerDialog,ShipmentDialog},
  data: () => ({
    start_of_current_month: moment().startOf('month').format('YYYY-MM-DD'),
    end_of_current_month: moment().endOf('month').format('YYYY-MM-DD'),
    qrScannerShow: false,
    currentShipmentQrCode: null,
    showShipmentDialog: false,
  }),
  methods:{
    onDecodeQrScanner: function(val)
    {
      this.qrScannerShow = false;
      this.currentShipmentQrCode = val;
      this.showShipmentDialog = true;
    },
    openShipmentDialog:function(shipment){
      this.qrScannerShow = false;
      this.currentShipmentQrCode = shipment.cod;
      this.showShipmentDialog = true;
    }
  }
}
</script>

<style scoped>

</style>