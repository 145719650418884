 <template>
  <v-container class='my-0 py-0'>
    <v-row >
      <v-col cols='12' sm="6" class='my-0 py-0'>
        <MjDateOrTimePicker 
          :label="label"
          mode="date"
          v-model="date_model"
          @input="onDateInput"
          :model_format="date_model_format"
          :display_format="date_display_format"
          :rules="rules"
        />
      </v-col>
      <v-col cols='12' sm="6" class='my-0 py-0'>
        <MjDateOrTimePicker
          label="Orario"
          mode="time"
          v-model="time_model"
          @input="onTimeInput"
          :model_format="time_model_format"
          :display_format="time_display_format"
          :rules="rules"
        />
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import moment from "moment";
import MjDateOrTimePicker from "@/components/form/MjDateOrTimePicker.vue";

export default {
  name: "MjDateTimePicker",
  components: { MjDateOrTimePicker },
  props: [
    "date_model_format",
    "date_display_format",
    "time_model_format",
    "time_display_format",
    "value",
    "label",
    "rules"
  ],
  computed: {
    date_time_model: {
      get: function () {
        if (this.time_model == null) {
          return this.date_model + " " + this.midnight;
        } else {
          return this.date_model + " " + this.time_model;
        }
      },
      set: function (val) {
        return val;
      },
    },
  },
  data: function () {
    return {
      date_model: null,
      time_model: null,
      midnight: moment("00:00", "HH:mm").format(this.time_model_format),
    };
  },
  mounted() {
    this.init(this.value);
  },
  methods: {
    init(val) {
      const datetime_moment = moment(
        val,
        this.date_model_format + " " + this.time_model_format,
        true
      );

      if (datetime_moment.isValid()) {
        this.date_model = datetime_moment.format(this.date_model_format);
        this.time_model = datetime_moment.format(this.time_model_format);
      }
    },
    onDateInput(val) {
      if (val == null || val == "") {
        this.date_model = "";
        this.time_model = "";
      } else {
        this.date_model = val;
      }

      this.$emit("input", this.date_time_model);
    },
    onTimeInput(val) {
      if (val == null || val == "") {
        this.time_model = this.midnight;
      } else {
        this.time_model = val;
      }
      this.$emit("input", this.date_time_model);
    },
  },
  watch: {
    value: function (val) {
      this.init(val);
    },
  },
};
</script>