<template>
  <v-container>
    <!--l per stà per load-->
    <span v-shortkey="['meta', 'l']" @shortkey="callApiUrl"></span>
    <!--r per stà per refresh (alias di load)-->
    <span v-shortkey="['meta', 'r']" @shortkey="callApiUrl"></span>
    <h4 class='mb-4'>ApiTester</h4>
    <v-text-field v-model="api_url" label="Url" required></v-text-field>

    <v-textarea name="params" label="Params" v-model="params"></v-textarea>

    <v-btn color="success" @click="callApiUrl"> Test It </v-btn>

    <v-progress-linear
      indeterminate
      v-show="show_loader"
      color="yellow darken-2"
      class="mt-3"
    ></v-progress-linear>

    <v-textarea
      class="mt-5"
      outlined
      name="result"
      label="Result"
      v-model="result"
      rows="100"
    ></v-textarea>
  </v-container>
</template>

<script>
import mj_axios from "@/mj_axios.js";
export default {
  data() {
    return {
      api_url: "/api/list/Attrezzatura/AttrezzaturaResource4SelectCollection",
      params: 'filters=[{"name":"ProgrammiManutenzione","type":"AND","val":[303],"glue":"and","relation_field_name":"id"}]',
      result: "",
      show_loader: false,
    };
  },
  methods: {
    callApiUrl: function () {
      if (this.params.length > 0 && !this.params.startsWith('?'))
      {
        this.params = '?' + this.params;
      }
      mj_axios
        .get(this.api_url+this.params)
        .then((response) => {
          this.result = JSON.stringify(response,null,2);
          // this.loading_overlay = false;
        })
        .catch((e) => {
          // this.error_show = true;
          // this.error_text = "Errore nel caricamento dati";
          // this.loading_overlay = false;
          this.result = JSON.stringify(e,null,2);
        })
        .then( () => {
          this.show_loader = false;
        });
    },
  },
};
</script>
