<template>
  <v-container fluid>

    <MjTableItems
        ref="tableProductionPlant"
        model-name-prop="ProductionPlant"
        resource-collection-name="ProductionPlantResourceListCollection"
    >
      <template #mj_top_toolbar_prepend="slotProps">
        <v-menu
            :close-on-content-click="false"
            v-model="showProductTypes"
        >
          <template v-slot:activator="{ on:menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on:tooltip }">
                <v-btn
                    v-show="slotProps.selected.length > 0"
                    icon
                    color="blue white--text"
                    class=""
                    v-on="{...tooltip, ...menu}"
                    v-bind="attrs"
                >
                  <v-icon>mdi-package-variant</v-icon>
                </v-btn>
              </template>
              <span>Associa Tipi Prodotti</span>
            </v-tooltip>
          </template>
          <MjSelectableList
              title="Tipologia prodotti"
              load_items_url="/api/product_types"
              belong_url="/api/parents_has_children"
              pivot_table="production_plants_has_product_types"
              parent_col_name="production_plant_id"
              child_col_name="product_type_id"
              parent_model_name="ProductionPlant"
              child_model_name="ProductType"
              relation_name="productTypes"
              list_title_field="cod"
              list_subtitle_field="name"
              max_height="300px"
              :exclusive="false"
              :parent_ids="slotProps.selected_ids"
              @close-popup="showProductTypes = false; $refs.tableProductionPlant.loadItems(true);"
          />
        </v-menu>
      </template>
    </MjTableItems>
  </v-container>
</template>

<script>
import MjTableItems from "@/components/MjTableItems";
import MjSelectableList from "@/components/MjSelectableList";

export default {
  name: "TableProductionPlants",
  components: {MjTableItems,MjSelectableList},
  data: () => ({
    showProductTypes: false,
  }),
}
</script>

<style scoped>

</style>