<template>
  <v-container fluid >
    <v-treeview

        shaped
        :search="search"
        v-model="tree"
        :items="items"
        dense
        item-key="id"
        item-children="all_children"
        open-on-click
        expand-icon=""
        :class="[size=='small' ? 'no_expand_icon' : '']"

    >
      <!-- <template v-slot:prepend="{ item, open }">
        <v-icon class='mt-4' large v-if="!item.link">{{ open ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
        <v-icon class='mt-4' large v-else>{{ item.icon }}</v-icon>
      </template>-->
      <template v-slot:label="{item, open}">
        <router-link :to="(item.link) ? item.link : ''" class="no_underline">
          <v-list-item class="pl-0 ml-0">
            <v-list-item-icon class="mr-3" v-if="!item.link">
              <v-icon :class=[textClass] style="opacity: 0.5" :large='size=="large"'>
                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-icon class="mr-3" v-else>
              <v-icon :class=[textClass] style="opacity: 0.5"  :large='size=="large"'>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                  v-bind:class='[(size=="small") ? "" : "overline", "text-truncate",textClass,"second_font" ]'>
                {{ item.title }}
              </v-list-item-title>
              <v-tooltip bottom v-if="item.sub_title">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-subtitle class='text--secondary caption mt-1 second_font' v-bind="attrs"
                                        v-on="on">{{ item.sub_title }}
                  </v-list-item-subtitle>
                </template>
                <span>{{ item.sub_title }}</span>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </template>
    </v-treeview>
  </v-container>
</template>
<style scoped>
.no_expand_icon {
  margin-left: -22px;
}
</style>
<script>
import mj_axios from "@/mj_axios.js";

export default {
  name: "MenuTree",
  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build
  components: {},
  //TODO impostata prop search per futura implementazione del campo di ricerca
  props: {
    size: {default: "small"},
    search_on: {default: () => false},
    apiUrl: {required: true},
    textClass: {default: () => ''}

  },
  data: () => ({
    search: null,
    tree: [],
    items: [],
    showLoader: false
  }),

  created() {
    this.showLoader = true;
    mj_axios
        .get(this.apiUrl)
        .then(response => {
          if (response.data.data)
            this.items = Object.values(response.data.data);
        })
        .then(() => {
          // always executed
          this.showLoader = false;
        });
  },
  methods: {
    //TODO da implementare per la ricerca (in modo ricorsivo perchè viene richiamato solo su quelli di livello 0)
    filter: function (/*item, search, textKey*/) {


      // return item[textKey].indexOf(search) > -1;
      return true;
    }
  }
};
</script>
