/*
Utilizza il componente direttamente l'sdk facebook
il Facebook app id e la versione delle api provengono da mj_config

Sul mounted viene inizializzato l'fb sdk
Sul login viene chiamata l'operazione di login al termine del quale viene chiamato un secondo metodo che dialoga
con il b.e. per inviare l'access token e consentire il login.

Il b.e. in caso positivo risponde con un tokenobject uguale a quello dell'operazione standard di login (email e pwd)
che starà gestito dallo store vuex.

*/
<template>
  <v-container>
        <v-btn class="fb_button" :disabled="showLoader" :loading="showLoader" @click="logInWithFacebook">
          <v-icon left class="mr-1">mdi-facebook</v-icon>Facebook Login
          <template v-slot:loader>
            <v-icon left class="mr-1">mdi-facebook</v-icon>
            <span>Caricamento...</span>
          </template>
        </v-btn>
    <v-snackbar
        app
        v-model="snackbar_show"
        type="warning"
        width="100%"
        color="info"
        vertical="vertical"
    >{{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
<style>

.fb_button {
  color: white !important;
  min-width: 150px;
  background-color: #3b5998 !important;
  height: 2.5rem;
}

</style>

<script>
import mj_axios from "@/mj_axios.js";
import store from "@/store.js";
import mj_config from "@/mj_config.js";


export default {
  name: "MjFacebookLoginButton",

  components: {},
computed:{
    dark_mode_on:()=>mj_config.dark_mode_on()
},
  async mounted() {
    this.showLoader = true;
    try {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
    } catch (err) {

      this.snackbar_show = true;
      this.snackbar_text = `Errore Nel Caricamento sistema di Login Facebook`;
      this.showLoader = false;
    }

  },
  data: () => ({
    snackbar_show: false,
    snackbar_text: '',
    showLoader: false,
    facebookAppId: mj_config.facebookAppId(),
    facebookApiVer: mj_config.facebookApiVer(),
  }),
  methods: {

    async logInWithFacebook() {
      window.FB.login( (response) => {
        if (response.authResponse) {
          // alert("You are logged in &amp; cookie set!");
          // Now you can redirect the user or do an AJAX request to
          // a PHP script that grabs the signed request from the cookie.
          this.onFacebookLoginSuccess(response);
        }
        // else {
        //   alert("User cancelled login or did not fully authorize.");
        // }
      },{scope: 'email'});
      return false;
    },
    async initFacebook() {


      window.fbAsyncInit = () => {

        window.FB.init({
          appId: this.facebookAppId, //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: this.facebookApiVer,
        });
        this.showLoader = false; //eseguito alla fine di FB.init
      };

    },
    async loadFacebookSDK(d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },


    onFacebookLoginSuccess(data) {

      if (data) {
        this.showLoader = true;

        mj_axios
            .get(
                "/api/user/facebook/verify_token_and_login/" + data.authResponse.accessToken
            )
            .then((response) => {
              const token_obj = response.data;
              store.commit("login_success", token_obj);
              this.$router.replace("/menu");
            })
            .catch((error) => {

              if (mj_config.allow_registration() && (error != null && error.response != null && error.response.status === 401)) {
                const query_string = (error.response.data.email) ? `?email=${error.response.data.email}` : '';
                this.$router.push(`/utente/registrati${query_string}`);
              } else {
                this.snackbar_show = true;
                const message = (error.response.data.message) ? error.response.data.message : '';
                this.snackbar_text = `Errore Nel Google Login ${message}`;
              }
            })
            .then(() => {
              this.showLoader = false;
            });
      } else {
        this.showLoader = false;
      }
    },
  },

}
;
</script>
