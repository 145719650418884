<template>
  <v-container>

    <MjEntityForm :title="'Viaggio ' + (($route.params.id) ? $route.params.id : 'Nuovo') " :form="form"
                  :apiUrl="apiUrl" @loadedItem="qrCode = form.qrCode.val; delete form.qrCode">
      <!--eslint-disable-next-line vue/no-unused-vars-->
      <template #fields="slotProps">
        <v-row justify="start">
          <v-col cols="2" >
            <v-img v-bind:src="(qrImageUrl)" ref="qrImage" lazy-src="@/assets/images/fake_qr_code.png" height="64" width="64" contain alt="`qr code ${shipmentQrCode}`"></v-img>
            {{qrCode}}
          </v-col>
          <v-col cols='3' class=" pl-0">
            <MjDateOrTimePicker
                label='Data di carico'
                v-model='form.loading_date.val'
                model_format='YYYY-MM-DD' mode='date' display_format='DD-MM-YYYY'
                :rules="[rules.required]">
            </MjDateOrTimePicker>
          </v-col>
          <v-col cols='6' class="text-right">
            <MjToggleButtons class="mr-sm-2" :multiple="false" elevation="2" load_items_url="/api/shipment_statuses"
                             item_description_field="text" item_value_field="value"
                             description_as_tooltip

                             v-model="form.status.val"
            ></MjToggleButtons>

          </v-col>

        </v-row>
          <v-row>
            <v-col cols='12' md='3'>
              <v-autocomplete
                  label='Prodotto'
                  v-model='form.product_type_id.val'
                  item-text="cod" item-value="id" :items="slotProps.selects.product_type_id" cache-items
                  :rules="[rules.required]">
              </v-autocomplete>
            </v-col>
          <v-col cols='12' md='3'>
            <v-autocomplete
                label='Impianto di produzione'
                v-model='form.production_plant_id.val'
                item-text="cod" item-value="id" :items="slotProps.selects.production_plant_id" cache-items
                :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='3'>
            <v-autocomplete
                label='Impianto Destinazione'
                v-model='form.destination_plant_id.val'
                item-text="cod" item-value="id" :items="slotProps.selects.destination_plant_id" cache-items
                :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
            <v-col cols='12' md='3'>
              <v-autocomplete
                  label='Intermediario'
                  v-model='form.intermediary_id.val'
                  item-text="cod" item-value="id" :items="slotProps.selects.intermediary_id" cache-items
                  >
              </v-autocomplete>
            </v-col>
        </v-row>
        <v-row>

          <v-col cols='12' md='4'>
            <v-autocomplete
                label='Trasportatore'
                v-model='form.carrier_id.val'
                item-text="cod" item-value="id" :items="slotProps.selects.carrier_id" cache-items
                :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='3'>
            <v-autocomplete
                label='Vehicle Types'
                v-model='form.vehicle_type_id.val'
                item-text="cod" item-value="id" :items="slotProps.selects.vehicle_type_id" cache-items
                :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='3'>
            <v-autocomplete
                label='Tipo Imballo'
                v-model='form.packaging_type_id.val'
                item-text="cod" item-value="id" :items="slotProps.selects.packaging_type_id" cache-items
                :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='2'>
            <v-text-field v-model="form.price.val" label="Prezzo"></v-text-field>
          </v-col>
        </v-row>
        <v-row>

          <v-col cols='12'>
            <v-textarea
                label='Nota Interna'
                v-model='form.internal_note.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
          <v-col cols='12'>
            <v-textarea
                label='Nota Trasportatore'
                v-model='form.note.val'
                rows='2'
            >
            </v-textarea>
          </v-col>

        </v-row>
        <v-row align="center">
          <v-col cols="12">
            <v-text-field label="Numero Revisione" outlined shaped reverse  readonly v-model="form.revision.val" append-outer-icon="mdi-plus"
            hint="Incrementa il numero di revisione se vuoi che il viaggio venga considerato nel caso di invio email pianificazione ai viaggiatori"
            persistent-hint
            @click:append-outer="form.revision.val++"
            >

            </v-text-field>
          </v-col>

        </v-row>

      </template>
    </MjEntityForm>
  </v-container>
</template>

<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjEntityForm from "@/components/form/MjEntityForm";
import MjDateOrTimePicker from '@/components/form/MjDateOrTimePicker.vue';
import MjToggleButtons from "@/components/form/MjToggleButtons";
import mj_config from "@/mj_config";


export default {
  name: "ShipmentFormView",
  mixins: [ValidationRulesMixin],
  components: {MjToggleButtons, MjEntityForm, MjDateOrTimePicker},
  computed: {
    qrImageUrl: function () {
      return this.$route.params.id ? `${mj_config.getBaseUrl()}/api/shipment/${(this.$route.params.id)}/qr_image` : '';
    }
    },
  data: () => ({
    console: console,
    qrCode:'',
    form: {
      status: {type: 'string', val: '', default_val: ''},
      qrCode:{type: 'string', val: '', default_val: ''},
      production_plant_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/production_plants'
      },
      destination_plant_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/destination_plants'
      },
      intermediary_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/intermediary'
      },
      product_type_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/product_types'},
      carrier_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/carriers'},
      vehicle_type_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/vehicle_types'},
      packaging_type_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/packaging_types'},
      internal_note: {type: 'text', val: '', default_val: ''},
      price: {type:'decimal', val:'', default_val:0},
      revision: {type:'integer', val:'', default_val:0},
      note: {type: 'text', val: '', default_val: ''},
      loading_date: {type: 'date', val: '', default_val: ''},
    },
    apiUrl: "/api/shipments"
  }),
};
</script>
