import mj_axios from "@/mj_axios";

const productionPlantMixin = {

    data: function () {
        return {
            productionPlants: [],
            loadingProductionPlants: 0,
            loadingSelectedProductionPlant:0,
            selectedProductionPlant: null,
        };
    },

    methods: {
        loadProductionPlants() {
            this.loadingProductionPlants++;
            const axios_promise = mj_axios.get('/api/production_plants');
            axios_promise.then(response => {
                this.productionPlants = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento tipi imballo, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingProductionPlants--;
            });
            return axios_promise;
        },
        //info sull'impianto selezionato tramite path parameter
        loadSelectedProductionPlant(productionPlantId) {
            this.loadingSelectedProductionPlant++;
            const axios_promise = mj_axios.get(`/api/production_plants/${productionPlantId}`);
            axios_promise.then(response => {
                this.selectedProductionPlant = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento impianto produzione selezionato, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingSelectedProductionPlant--;
            });
            return axios_promise;
        },
    },

};

export default productionPlantMixin;
