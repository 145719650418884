import mj_axios from "@/mj_axios";

const productTypeMixin = {

    data: function () {
        return {
            productTypes: [],
            loadingProductTypes: 0,
            selectedProductType: null,
            loadingSelectedProductType:null,
        };
    },

    methods: {

        loadProductTypesForProductionPlant(productionPlantId) {
            this.loadingProductTypes++;
            const axios_promise = mj_axios.get(`/api/production_plants/${productionPlantId}/product_types`);
            axios_promise.then(response => {
                this.productTypes = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento tipi prodotti per impianto di produzione selezionato, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingProductTypes--;
            });
            return axios_promise;
        },
        loadProductTypes() {
            this.loadingProductTypes++;
            const axios_promise = mj_axios.get(`/api/product_types/`);
            axios_promise.then(response => {
                this.productTypes = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento tipi prodotti per impianto di produzione selezionato, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingProductTypes--;
            });
            return axios_promise;
        },
        loadSelectedProductType(productTypeId) {
            this.loadingSelectedProductType++;
            const axios_promise = mj_axios.get(`/api/product_types/${productTypeId}`);
            axios_promise.then(response => {
                this.selectedProductType = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento impianto produzione selezionato, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingSelectedProductType--;
            });
            return axios_promise;
        },
    },

};

export default productTypeMixin;
